import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { logIn, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import Logo from "../../app/components/integra-logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const navigate = useNavigate();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            autoCapitalize="false"
            {...other}
        >
            {value === index && (
                { ...children }
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Login = ({ logIn, user, token, signUp, isLoading, close }) => {


    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [terms, setTerms] = React.useState(false);
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();

    const handleSignupSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("password") !== data.get("password-confirm")) {
            setError(t("password_error"))
        } else {
            const creation = {
                name: data.get("firstName") + " " + data.get("lastName"),
                email: data.get("email"),
                mobile: data.get("mobile"),
                password: data.get("password")
            }
            signUp(creation, navigate);
            close && close()
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            login: data.get('login'),
            password: data.get('password')
        }
        logIn(details);
        close && close()
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (user) {
        try{
            window.ReactNativeWebView.postMessage(token);
        } catch (err) {
            console.log(err)
        }
        navigate("/")
    }

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Image preview={false} src={Logo} height="100px" width="227px" />
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs textColor='primary.main' value={value} centered onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={t("login.login")} sx={{ mx: 3, fontWeight: "bold" }} {...a11yProps(0)} />
                            <Tab label={t("login.signup")} sx={{ mx: 3, fontWeight: "bold" }} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="login"
                                label={t("login.login_details")}
                                name="login"
                                autoComplete="email"
                                autoFocus
                            />
                            <MainPassword
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t("login.password")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, ...main_button }}
                            >
                                {t("login.login")}
                            </Button>
                            <Button onClick={() => navigate("/forgot-password")} fullWidth sx={{ color: "var(--secColor)" }}>
                                {t("login.forgot_password")}
                            </Button>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box component="form" onSubmit={handleSignupSubmit} sx={{ mt: 1 }}>
                            <Grid container spacing={2} sx={{ direction: "row" }}>
                                <Grid item xs={6}>
                                    <MainInput
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label={t("login.first_name")}
                                        name="firstName"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MainInput
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label={t("login.last_name")}
                                        name="lastName"
                                    />
                                </Grid>
                            </Grid>
                            <MainInput
                                margin="normal"
                                type="email"
                                required
                                fullWidth
                                id="email"
                                label={t("login.email")}
                                name="email"
                            />
                            <MuiPhoneNumber
                                sx={{
                                    "& label": {
                                        color: "var(--secColor) !important",
                                    },
                                    "& label.Mui-focused": {
                                        color: "var(--secColor) !important",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: "1px solid var(--secColor)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "var(--terColor)",
                                        },

                                        borderRadius: 0.5,
                                    },
                                }}
                                margin="normal"
                                fullWidth
                                required
                                id="mobile"
                                label={t("login.mobile")}
                                name="mobile"
                                variant='outlined'
                                defaultCountry={'eg'}
                                preferredCountries={['eg']}
                                countryCodeEditable={false}
                                inputProps={{
                                    minLength: 13
                                }}
                                disableAreaCodes
                            />
                            <MainPassword
                                margin="normal"
                                sx={{ my: 1 }}
                                required
                                fullWidth
                                name="password"
                                label={t("login.password")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <MainPassword
                                margin="normal"
                                sx={{ my: 1 }}
                                required
                                fullWidth
                                name="password-confirm"
                                label={t("login.confirm_password")}
                                type="password"
                                id="password-confirm"
                                error={error}
                            />
                            <Checkbox value={terms} onChange={() => setTerms(!terms)} />
                            <Button sx={{ ...sec_button }} href="/terms" target="_blank">
                                {t("login.terms")}
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={!terms}
                                sx={{ mt: 3, mb: 2, ...main_button }}
                            >
                                {t("login.signup")}
                            </Button>
                        </Box>
                    </CustomTabPanel>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { logIn, signUp };

export default connect(mapStateToProps, mapDispatchToProps)(Login);