import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
import BoltIcon from '@mui/icons-material/Bolt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import { connect } from "react-redux";
import { logout } from '../store/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Drawer, FormControl, Grid, InputAdornment, InputLabel, NativeSelect, Select, Tooltip } from '@mui/material';
import { MainInput, MainSearch, centered_flex_box, left_flex_box, right_flex_box } from './Styles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import StarsIcon from '@mui/icons-material/Stars';
import Logo from "./integra-logo.png"
import { Image } from 'antd';
import Cookies from "js-cookie";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Analytics, ArrowForward, Close, ExpandMore, Favorite, Inventory, Message, RequestQuote, Search, Store } from '@mui/icons-material';
import LoginModal from './LoginModal';
import { getCategories, getProducts } from '../store/actions/commonActions';

function MenuAppBar({ auth, logout, getCategories, categories }) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [search, setSearch] = useState(null);

    const user = auth?.user;
    const token = auth?.token || '';
    const header = token.split(' ')
    const role = header[0]
    const location = useLocation();

    const currentLocale = Cookies.get("i18next") || "en";
    const languages = [
        { name: "English", code: "en" },
        { name: "العربية", code: "ar", dir: "rtl" },
    ];
    const [language, setLanguage] = useState(currentLocale);
    const handleLocaleChange = (e) => {
        const lang = e.target.value;
        setLanguage(lang);
        i18n.changeLanguage(lang);
    };
    const currentLangObj = languages.find((lang) => lang.code === currentLocale);

    useEffect(() => {
        document.body.dir = currentLangObj.dir || 'ltr'
    }, [currentLangObj])

    useEffect(() => {
        getCategories({})
    }, [])

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDrawerOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDrawerOpen(false)
    };

    const handleLogin = () => {
        setAnchorEl(null);
        if (isMobile) {
            route("/login")
        } else {
            setLoginOpen(true)
        }
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout();
    };

    const handleHome = () => {
        setAnchorEl(null);
        navigate("/");
    };

    const handleProfile = () => {
        setAnchorEl(null);
        let path = "/" + role + "/profile";
        navigate(path);
    };

    const route = (path) => {
        setAnchorEl(null);
        navigate(path);
    }

    const handleSearch = () => {
        getProducts({ search, page: 1, limit: 500 });
        setAnchorEl(null);
        navigate(`/store/results/${search}`);
    }

    const menuItems = <>
        <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
            <MobileView><IconButton className='position-absolute top-0 end-0' onClick={handleClose}><Close /></IconButton></MobileView>
            <Image preview={false} src={Logo} height={50} width={113} />
            <MainSearch onChange={(event) => setSearch(event.target.value)} sx={{ marginTop: 2 }} label={t("menu.search")} InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton onClick={handleSearch}>
                            <Search sx={{ color: "var(--secColor)" }} />
                        </IconButton>
                    </InputAdornment>
            }} />
        </Box>
        < hr />
        <MenuItem onClick={() => route("/store")}><Store /><Typography fontWeight="bold" marginX={2}>{t("menu.store")}</Typography></MenuItem>
        <MenuItem>
            <Accordion sx={{ width: "100%", "box-shadow": 'none !important' }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ padding: 0 }}
                >
                    <Inventory /><Typography marginX={2}>{t("menu.categories")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {categories?.map((category) => {
                        return (
                            <Accordion sx={{ width: "100%", "box-shadow": 'none !important' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    sx={{ padding: 0 }}
                                >
                                    <Image width={20} src={category.image} preview={false} /><Typography sx={{ marginInlineStart: 1 }} >{category[`name_${currentLocale}`]}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MenuItem onClick={() => route(`/category/${category._id}`)}><Typography fontWeight="bold">{category[`name_${currentLocale}`]}</Typography></MenuItem>
                                    <hr />
                                    {category.subcategories?.map((subcategory) => {
                                        return (
                                            <MenuItem onClick={() => route(`/subcategory/${subcategory._id}`)}><Typography>{subcategory[`name_${currentLocale}`]}</Typography></MenuItem>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </AccordionDetails>
            </Accordion>
        </MenuItem>
        <MenuItem onClick={() => route("/about")}><InfoIcon /><Typography marginX={2}>{t("menu.about")}</Typography></MenuItem>
        <MenuItem onClick={() => route("/contact")}><Message /><Typography marginX={2}>{t("menu.contact")}</Typography></MenuItem>
        <hr />
        {!user && <MenuItem onClick={() => handleLogin()}><LoginIcon /><Typography marginX={2}>{t("menu.login")}</Typography></MenuItem>}
        {user && role === "User" && <MenuItem onClick={() => route("/user/account")}><AccountCircle /><Typography marginX={2}>{t("menu.my_account")}</Typography></MenuItem>}
        {user && role === "User" && <MenuItem onClick={() => route("/user/favourites")}><Favorite /><Typography marginX={2}>{t("favourites")}</Typography></MenuItem>}
        {user && role === "Super" && <MenuItem onClick={() => route("/super/account")}><AdminPanelSettingsIcon /><Typography marginX={2}>{t("menu.actions")}</Typography></MenuItem>}
        {user && role === "Super" && <MenuItem onClick={() => route("/super/orders")}><ReceiptIcon /><Typography marginX={2}>{t("menu.orders")}</Typography></MenuItem>}
        {user && role === "Super" && <MenuItem onClick={() => route("/super/analytics")}><Analytics /><Typography marginX={2}>{t("menu.analytics")}</Typography></MenuItem>}
        {user && role === "User" && <MenuItem onClick={() => route("/user/orders")}><ReceiptIcon /><Typography marginX={2}>{t("menu.my_orders")}</Typography></MenuItem>}
        {user && role === "User" && <MenuItem onClick={() => route("/cart")}><ShoppingCartIcon /><Typography marginX={2}>{t("menu.my_cart")}</Typography></MenuItem>}
        {user && <MenuItem onClick={handleLogout}><LogoutIcon color='error' /><Typography color='error' marginX={2}>{t("menu.logout")}</Typography></MenuItem>}
        <hr />
        <FormControl fullWidth variant="standard">
            <InputLabel id="language-select">{t("menu.language")}</InputLabel>
            <Select
                labelId="language-select"
                fullWidth
                id="language"
                value={language}
                label={t("language")}
                onChange={handleLocaleChange}
            >
                {languages.map((lang, i) => {
                    return <MenuItem key={i} value={lang.code}>{lang.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    </>


    return (
        <Box sx={{ flexGrow: 1, color: "var(--secColor)", paddingBottom: 9 }}>
            <BrowserView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                    <Toolbar sx={{ margin: 1 }}>
                        <Grid container>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <Image preview={false} src={Logo} height="80px" onClick={handleHome} style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <IconButton onClick={handleMenu}><MenuIcon /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={currentLangObj.dir === "rtl" ? "left" : "right"}>
                        <Drawer
                            anchor={currentLangObj.dir === "rtl" ? "left" : "right"}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box sx={{ width: "25vw", padding: 1 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
                <LoginModal open={loginOpen} handleAccept={() => setLoginOpen(false)} handleClose={() => setLoginOpen(false)} />
            </BrowserView>
            <MobileView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ ...(currentLangObj.dir === "rtl" ? { ml: 2 } : { mr: 2 }) }}
                                    onClick={() => navigate(-1)}
                                >
                                    {currentLangObj.dir === "rtl" ? <ArrowForward /> : <ArrowBackIcon />}
                                </IconButton>

                                <Box sx={left_flex_box}>
                                    <Image preview={false} src={Logo} onClick={handleHome} style={{ cursor: "pointer" }} />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <IconButton onClick={handleMenu}><MenuIcon /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={"top"}>
                        <Drawer
                            anchor="top"
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box sx={{ padding: 2 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
            </MobileView>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth,
    categories: state?.records?.categories
});

const mapDispatchToProps = { logout, getCategories };

export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar);