import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Dialog, Pagination } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getBrand, newsletter } from '../../app/store/actions/commonActions';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import { StoreHome } from '../Store';
import { useEffect } from 'react';
import { Favorite, Inbox, MoreHoriz } from '@mui/icons-material';
import ProductCard from '../../app/components/ProductCard';
import { useState } from 'react';
import { getFavourites } from '../../app/store/actions/usersActions';

export const Favourites = ({ getFavourites, isLoading, products }) => {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getFavourites({})
    }, [])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ minWidth: "100vw", minHeight: isMobile ? "20vh" : "40vh", mt: -2, mb: "74px", backgroundImage: "url(https://integraecommerce.s3.eu-west-3.amazonaws.com/logos/background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center', position: "relative" }}>
                    <Avatar className="position-absolute top-100 start-50 translate-middle" sx={{ width: 128, height: 128, background: "var(--terColor)", padding: 2 }} >
                        <Favorite sx={{ width: 128, height: 128, color: "var(--secColor)", padding: 2}} />
                    </Avatar>
                </Box>
                <Typography fontWeight="bold">{t("favourites")}</Typography>
            </Box>
            <BrowserView>
                <Box sx={{ flexDirection: "column" }}>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }}>
                        {products?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3}>
                                <ProductCard product={product.product} navigate={navigate} />
                            </Grid>
                        })}
                        {products?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </BrowserView>
            <MobileView>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Grid container spacing={1}>
                        {products?.length > 0 &&
                            <>
                                {products?.map((product) => {
                                    return (
                                        <Grid item xs={6}>
                                            <ProductCard product={product.product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {products?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </MobileView>

        </Container>
    );
}

const mapStateToProps = (state) => ({
    brand: state?.records?.brand,
    products: state?.records?.favourites,
    isLoading: state?.records?.isFavLoading
});

const mapDispatchToProps = { getFavourites };

export default connect(mapStateToProps, mapDispatchToProps)(Favourites);