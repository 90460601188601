import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Dialog, Pagination, Card, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getCategory } from '../../app/store/actions/commonActions';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import { StoreHome } from '../Store';
import { useEffect } from 'react';
import { Inbox, MoreHoriz } from '@mui/icons-material';
import ProductCard from '../../app/components/ProductCard';
import { useState } from 'react';
import Cookies from 'js-cookie';

export const CategoryHome = ({ getCategory, isLoading, category, products, subcategories }) => {

    const navigate = useNavigate()
    const categoryId = useParams().id
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();
    const [filters, setFilters] = useState({
        page: 1,
        limit: 100
    })

    const { page, limit } = filters

    const handlePageChange = (event, value) => {
        setFilters({ ...filters, page: value })
    }

    useEffect(() => {
        getCategory({ ...filters, categoryId })
    }, [filters, categoryId])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ minWidth: "100vw", minHeight: isMobile ? "20vh" : "40vh", mt: -2, mb: "74px", backgroundImage: "url(https://integraecommerce.s3.eu-west-3.amazonaws.com/logos/background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center', position: "relative" }}>
                    <Avatar className="position-absolute top-100 start-50 translate-middle" alt={category && category[`name_${currentLocale}`]} src={category?.image} sx={{ width: 128, height: 128, background: "var(--terColor)", padding: 2 }} />
                </Box>
                <Typography fontWeight="bold">{category && category[`name_${currentLocale}`]}</Typography>
            </Box>
            <Box sx={{ flexDirection: "column", mt: isMobile ? 2 : 5 }}>
                <Typography fontSize={isMobile ? 20 : 30}>{t("menu.categories")}</Typography>
                {subcategories &&
                    <Grid container sx={{...centered_flex_box}} spacing={2}>
                        {subcategories.map((subcategory) => {
                            return (
                                <Grid key={subcategory._id} item xs={isMobile ? 6 : 3}>
                                    <Card onClick={() => navigate(`/subcategory/${subcategory._id}`)} sx={{ ...centered_flex_box, flexDirection: "column", cursor: "pointer", height: 150 }}>
                                        <CardContent sx={{ ...centered_flex_box, flexDirection: "column" }}>
                                            <Image src={category.image} width="25%" preview={false} />
                                        </CardContent>
                                        <CardContent>
                                            <Typography textAlign="center" variant="body2">{subcategory[`name_${currentLocale}`]}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>}
            </Box>
            <BrowserView>
                <Box sx={{ flexDirection: "column" }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("products.products")}</Typography>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={2}>
                        {products?.docs?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3}>
                                <ProductCard product={product} navigate={navigate} />
                            </Grid>
                        })}
                        {products?.docs?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </BrowserView>
            <MobileView>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Typography fontWeight="bold" fontSize={isMobile ? 20 : 30}>{t("products.products")}</Typography>
                    <Grid container spacing={1}>
                        {products?.docs?.length > 0 &&
                            <>
                                {products?.docs?.map((product) => {
                                    return (
                                        <Grid item xs={6}>
                                            <ProductCard product={product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {products?.docs?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </MobileView>
            <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                <Pagination count={products?.pages || 1} page={page} onChange={handlePageChange} />
            </Box>

        </Box>
    );
}

const mapStateToProps = (state) => ({
    category: state?.records?.category,
    subcategories: state?.records?.subcategories,
    products: state?.records?.products,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getCategory };

export default connect(mapStateToProps, mapDispatchToProps)(CategoryHome);