import * as React from "react";
import {
    Container,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export const Refund = ({ }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";

    return (
        <Container sx={{ padding: 10 }}>
            {currentLocale === "ar" ? <>
                <h1>سياسة الخصوصية</h1>
                <p>آخر تحديث: 29 أكتوبر 2023</p>
                <p>تصف هذه السياسة الخصوصية سياستنا وإجراءاتنا بشأن جمع المعلومات الخاصة بك واستخدامها وكشفها عند استخدامك للخدمة وتخبرك عن حقوق الخصوصية الخاصة بك وكيفية حمايتك بموجب القانون.</p>
                <p>نحن نستخدم بياناتك الشخصية لتقديم وتحسين الخدمة. من خلال استخدام الخدمة، فإنك توافق على جمع واستخدام المعلومات وفقًا لهذه السياسة الخصوصية. تم إعداد هذه السياسة الخصوصية بمساعدة <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">مولد سياسة الخصوصية</a>.</p>
                <h2>التفسير والتعاريف</h2>
                <h3>التفسير</h3>
                <p>تحمل الكلمات التي يبدأ حرفها الأول بحرف كبير معانٍ محددة في الشروط التالية. تكون التعاريف التالية لها نفس المعنى بغض النظر عما إذا كانت تظهر في الواحد أو الجمع.</p>
                <h3>التعاريف</h3>
                <p>لأغراض هذه السياسة الخصوصية:</p>
                <ul>
                    <li>
                        <p><strong>الحساب</strong> يعني حساب فريد تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.</p>
                    </li>
                    <li>
                        <p><strong>الشريك</strong> يعني كيانًا يتحكم فيه الطرف أو يتحكم فيه أو يتمتع بالتحكم المشترك مع الطرف، حيث يعني "التحكم" ملكية 50٪ أو أكثر من الأسهم أو حصص الأرباح أو الأوراق المالية الأخرى المخولة بالتصويت لانتخاب مديرين أو أخرى تتولى الإدارة.</p>
                    </li>
                    <li>
                        <p><strong>الشركة</strong> (المشار إليها باسم "الشركة" أو "نحن" أو "لنا" في هذا الاتفاق) تشير إلى إنتجرا لحلول البناء وتكنولوجيا المعلومات، القاهرة الجديدة، مصر.</p>
                    </li>
                    <li>
                        <p><strong>الكوكيز</strong> هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر الخاص بك، الهاتف المحمول أو أي جهاز آخر عبر موقع ويب، تحتوي على تفاصيل تاريخ تصفحك على تلك الموقع بين استخدامات أخرى كثيرة.</p>
                    </li>
                    <li>
                        <p><strong>البلد</strong> يشير إلى: مصر</p>
                    </li>
                    <li>
                        <p><strong>الجهاز</strong> يعني أي جهاز يمكنه الوصول إلى الخدمة مثل الكمبيوتر أو الهاتف النقال أو اللوحة الرقمية.</p>
                    </li>
                    <li>
                        <p><strong>البيانات الشخصية</strong> هي أي معلومات تتعلق بشخص محدد أو قابل للتحديد.</p>
                    </li>
                    <li>
                        <p><strong>الخدمة</strong> تشير إلى الموقع على الويب.</p>
                    </li>
                    <li>
                        <p><strong>مقدم الخدمة</strong> يعني أي شخص طبيعي أو شخص قانوني يعالج البيانات نيابة عن الشركة. يشير إلى شركات أو أفراد من الطرف الثالث توظفهم الشركة لتسهيل الخدمة، وتقديم الخدمة نيابة عن الشركة، وأداء الخدمات المتعلقة بالخدمة، أو مساعدة الشركة في تحليل كيفية استخدام الخدمة.</p>
                    </li>
                    <li>
                        <p><strong>بيانات الاستخدام</strong> تشير إلى البيانات التي يتم جمعها تلقائيًا، سواء تم إنشاءها بواسطة استخدام الخدمة أو من بنية الخدمة نفسها (على سبيل المثال، مدة زيارة صفحة).</p>
                    </li>
                    <li>
                        <p><strong>الموقع الإلكتروني</strong> يشير إلى إنتجرا للتجارة الإلكترونية، المتاحة على <a className="a2" href="https://www.integraegy.com" rel="external nofollow noopener" target="_blank">https://www.integraegy.com</a></p>
                    </li>
                    <li>
                        <p><strong>أنت</strong> تعني الشخص الذي يستخدم الخدمة أو الشركة أو الكيان القانوني الآخر نيابة عن هذا الشخص الذي يستخدم الخدمة، حسب الاقتضاء.</p>
                    </li>
                </ul>
                <h2>جمع واستخدام بياناتك الشخصية</h2>
                <h3>أنواع البيانات المجمعة</h3>
                <h4>البيانات الشخصية</h4>
                <p>أثناء استخدام خدمتنا، قد نطلب منك تزويدنا بمعلومات شخصية معينة يمكن استخدامها للاتصال بك أو التعرف عليك. قد تشمل المعلومات الشخصية التي يمكن التعرف عليها، ولكن لا تقتصر على ذلك:</p>
                <ul>
                    <li>
                        <p>عنوان البريد الإلكتروني</p>
                    </li>
                    <li>
                        <p>الاسم الأول والاسم الأخير</p>
                    </li>
                    <li>
                        <p>رقم الهاتف</p>
                    </li>
                    <li>
                        <p>العنوان، الولاية، المقاطعة، الرمز البريدي، المدينة</p>
                    </li>
                    <li>
                        <p>بيانات الاستخدام</p>
                    </li>
                </ul>
                <h4>بيانات الاستخدام</h4>
                <p>تتم جمع بيانات الاستخدام تلقائيًا أثناء استخدام الخدمة.</p>
                <p>قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت لجهازك (على سبيل المثال، عنوان الآي بي)، نوع المتصفح، نسخة المتصفح، الصفحات التي تزورها على خدمتنا، ووقت وتاريخ زيارتك، والوقت الذي قضيته في تلك الصفحات، ومعرفات الأجهزة الفريدة وبيانات تشخيصية أخرى.</p>
                <p>عندما تستخدم الخدمة من خلال جهاز محمول أو غير ذلك من الأجهزة، قد نجمع معلومات معينة تلقائيًا، بما في ذلك، ولكن لا تقتصر على، نوع الجهاز المحمول الذي تستخدمه، الهوية الفريدة لجهازك المحمول، عنوان الآي بي لجهازك المحمول، نظام التشغيل الخاص بجهازك المحمول، نوع متصفح الإنترنت على الإنترنت الذي تستخدمه، ومعرفات أجهزة فريدة وبيانات تشخيصية أخرى.</p>
                <p>قد نقوم أيضًا بجمع معلومات ترسلها متصفحك كلما زرت خدمتنا أو عندما تستخدم الخدمة من خلال جهاز محمول.</p>
                <h4>تقنيات التتبع وملفات تعريف الارتباط</h4>
                <p>نحن نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتتبع الأنشطة على خدمتنا وتخزين معلومات معينة. تتضمن التقنيات التي نستخدمها شعارات البيكون، والوسوم، والنصوص لجمع وتتبع المعلومات ولتحسين وتحليل خدمتنا. يمكن أن تشمل التقنيات التي نستخدمها:</p>
                <ul>
                    <li><strong>ملفات تعريف الارتباط أو ملفات تعريف الارتباط للمتصفح.</strong> ملف تعريف الارتباط هو ملف صغير يُوضع على جهازك. يمكنك تعليم متصفحك لرفض جميع ملفات تعريف الارتباط أو للإشارة عند إرسال ملف تعريف الارتباط. ومع ذلك، إذا لم تقبل ملفات تعريف الارتباط، قد لا تتمكن من استخدام بعض أجزاء خدمتنا. ما لم تقم بضبط إعداد متصفحك بحيث يرفض ملفات تعريف الارتباط، ستقوم خدمتنا باستخدام ملفات تعريف الارتباط.</li>
                    <li><strong>شعارات البيكون.</strong> يمكن أن تحتوي بعض أقسام خدمتنا ورسائل البريد الإلكتروني لدينا على ملفات إلكترونية صغيرة تُعرف باسم شعارات البيكون (تُعرف أيضًا بشعارات واضحة وعلامات البكسل وشعارات بكسل واحد) تتيح للشركة، على سبيل المثال، عد الأشخاص الذين قاموا بزيارة تلك الصفحات أو فتح بريد إلكتروني وإحصائيات موقع الويب ذات الصلة الأخرى (مثل تسجيل شعبية قسم معين والتحقق من سلامة النظام والخادم).</li>
                </ul>
                <p>ملفات تعريف الارتباط يمكن أن تكون "مستمرة" أو "جلسة". تبقى ملفات تعريف الارتباط المستمرة على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك عندما تكون غير متصل بالإنترنت، بينما تتم حذف ملفات تعريف الارتباط بجلسة بمجرد إغلاق متصفح الويب الخاص بك. اعرف المزيد عن ملفات تعريف الارتباط على <a href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking" target="_blank">موقع سياسات الخصوصية</a>.</p>
                <p>نستخدم ملفات تعريف الارتباط بكل من أنواعها، ملفات تعريف الارتباط للجلسة وملفات تعريف الارتباط المستمرة للأغراض المبينة أدناه:</p>
                <ul>
                    <li>
                        <p><strong>ملفات تعريف الارتباط الضرورية / الأساسية.</strong></p>
                        <p>النوع: ملفات تعريف الارتباط للجلسة</p>
                        <p>إدارتها بواسطة: نحن</p>
                        <p>الغرض: تعتبر هذه ملفات تعريف الارتباط ضرورية لتوفير خدمات متاحة من خلال الموقع ولتمكينك من استخدام بعض ميزاته. تساعد في التحقق من هوية المستخدمين ومنع الاستخدام الاحتيالي لحسابات المستخدمين. بدون هذه ملفات تعريف الارتباط، لا يمكن توفير الخدمات التي طلبتها، ونستخدم هذه ملفات تعريف الارتباط فقط لتقديم هذه الخدمات لك.</p>
                    </li>
                    <li>
                        <p><strong>ملفات تعريف الارتباط لقبول سياسة / إشعار الارتباط.</strong></p>
                        <p>النوع: ملفات تعريف الارتباط المستمرة</p>
                        <p>إدارتها بواسطة: نحن</p>
                        <p>الغرض: تعرف هذه ملفات تعريف الارتباط ما إذا كان المستخدمون قد قبلوا استخدام ملفات تعريف الارتباط على الموقع.</p>
                    </li>
                    <li>
                        <p><strong>ملفات تعريف الارتباط للوظائف.</strong></p>
                        <p>النوع: ملفات تعريف الارتباط المستمرة</p>
                        <p>إدارتها بواسطة: نحن</p>
                        <p>الغرض: تتيح لنا هذه ملفات تعريف الارتباط تذكر الخيارات التي تقوم باتخاذها عند استخدام الموقع، مثل تذكر تفاصيل تسجيل الدخول الخاصة بك أو تفضيل اللغة. الهدف من هذه ملفات تعريف الارتباط هو توفير تجربة شخصية أكثر لك وتجنب عليك إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها الموقع.</p>
                    </li>
                </ul>
                <p>لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها واختياراتك بشأن ملفات تعريف الارتباط، يرجى زيارة سياسة ملفات تعريف الارتباط الخاصة بنا أو القسم المخصص لملفات تعريف الارتباط في سياستنا الخصوصية.</p>
                <h3>استخدام بياناتك الشخصية</h3>
                <p>قد تستخدم الشركة البيانات الشخصية للأغراض التالية:</p>
                <ul>
                    <li>
                        <p><strong>لتوفير وصيانة خدمتنا</strong>، بما في ذلك مراقبة استخدام خدمتنا.</p>
                    </li>
                    <li>
                        <p><strong>لإدارة حسابك:</strong> لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها وصولًا إلى وظائف مختلفة من الخدمة المتاحة لك كمستخدم مسجل.</p>
                    </li>
                    <li>
                        <p><strong>لأداء عقد:</strong> تنفيذ وامتثال لعقد الشراء للمنتجات أو العناصر أو الخدمات التي قمت بشرائها أو أي عقد آخر معنا من خلال الخدمة.</p>
                    </li>
                    <li>
                        <p><strong>للاتصال بك:</strong> للاتصال بك عبر البريد الإلكتروني أو المكالمات الهاتفية أو الرسائل النصية أو وسائل الاتصال الإلكترونية المماثلة الأخرى، مثل إشعارات التطبيق المحمول بشأن التحديثات أو الاتصالات المعلوماتية ذات الصلة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها، بما في ذلك تحديثات الأمان، عند الضرورة أو العقلانية لتنفيذها.</p>
                    </li>
                    <li>
                        <p><strong>لتقديم لك</strong> أخبار وعروض خاصة ومعلومات عامة حول السلع والخدمات والفعاليات الأخرى التي نقدمها والتي تشبه تلك التي قد قمت بشرائها أو استفسار حولها بالفعل ما لم تكن قد اخترت عدم استلام مثل هذه المعلومات.</p>
                    </li>
                    <li>
                        <p><strong>لإدارة طلباتك:</strong> للرد على طلباتك وإدارتها لدينا.</p>
                    </li>
                    <li>
                        <p><strong>لعمليات الأعمال:</strong> قد نستخدم معلوماتك لأغراض أخرى، مثل تحليل البيانات، وتحديد اتجاهات الاستخدام، وتقدير فعالية حملات الترويج ولتقييم وتحسين خدمتنا ومنتجاتنا وخدماتنا وتسويقنا وتجربتك.</p>
                    </li>
                </ul>
                <p>قد نشارك معلوماتك الشخصية في الحالات التالية:</p>
                <ul>
                    <li><strong>مع مقدمي الخدمة:</strong> قد نشارك معلوماتك الشخصية مع مقدمي الخدمة لرصد وتحليل استخدام خدمتنا وللاتصال بك.</li>
                    <li><strong>لعمليات النقل التجارية:</strong> قد نشارك أو ننقل معلوماتك الشخصية في سياق عمليات الدمج، أو الاستحواذ، أو بيع أصول الشركة، أو تمويل الشركة، أو اقتناء كامل أو جزء من أعمالنا إلى شركة أخرى.</li>
                    <li><strong>مع الشركات التابعة:</strong> قد نشارك معلوماتك مع شركاتنا التابعة، وفي هذه الحالة سنطلب من تلك الشركات التابعة أن تلتزم بسياسة الخصوصية هذه. تشمل الشركات التابعة شركتنا الأم وأي شركات فرعية أخرى، وشركاء الشركات المشتركة أو أي شركات أخرى تكون تحت نفس السيطرة معنا.</li>
                    <li><strong>مع شركاء الأعمال:</strong> قد نشارك معلوماتك مع شركاء الأعمال لنقدم لك منتجات أو خدمات أو عروض ترويجية معينة.</li>
                    <li><strong>مع مستخدمين آخرين:</strong> عندما تشارك معلومات شخصية أو تتفاعل بأي شكل في المناطق العامة مع مستخدمين آخرين، قد يمكن لهؤلاء المستخدمين رؤية تلك المعلومات وقد يتم نشرها علناً.</li>
                    <li><strong>بموافقتك:</strong> قد نكشف عن معلوماتك الشخصية لأغراض أخرى بموافقتك.</li>
                </ul>
                <h3>الاحتفاظ ببياناتك الشخصية</h3>
                <p>سنحتفظ ببياناتك الشخصية فقط طالما كان ذلك ضروريًا لأغراض موجودة في سياسة الخصوصية هذه. سنحتفظ وسنستخدم بياناتك الشخصية بقدر الضرورة للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا كنا ملزمين بالاحتفاظ ببياناتك للامتثال للقوانين السارية)، ولحل النزاعات، وتنفيذ اتفاقاتنا القانونية وسياساتنا.</p>
                <p>سنحتفظ أيضًا ببيانات الاستخدام لأغراض تحليلية داخلية. تعتبر بيانات الاستخدام عمومًا محفوظة لمدة أقصر من الزمن، ما لم يتعين استخدام هذه البيانات لتعزيز الأمان أو تحسين وظائف خدمتنا، أو نكون ملزمين قانونيًا بالاحتفاظ بها لفترات زمنية أطول.</p>
                <h3>نقل بياناتك الشخصية</h3>
                <p>تتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب الشركة وفي أي مكان آخر حيث يتم العمل في عمليات المعالجة. وهذا يعني أن هذه المعلومات قد تنقل إلى أجهزة الكمبيوتر الموجودة خارج دولتك أو إقليمك أو بلدك أو أي سلطة حكومية أخرى حيث قوانين حماية البيانات قد تكون مختلفة عن تلك المعمول بها في دولتك.</p>
                <p>موافقتك على هذه سياسة الخصوصية وتقديم مثل هذه المعلومات تمثل موافقتك على تلك النقلات.</p>
                <p>ستتخذ الشركة جميع الخطوات اللازمة لضمان أن يتم التعامل مع بياناتك بأمان ووفقًا لهذه سياسة الخصوصية ولن يتم نقل بياناتك الشخصية إلى منظمة أو دولة ما مالم تكن هناك ضوابط كافية مثل الأمان الخاص ببياناتك ومعلوماتك الشخصية الأخرى.</p>
                <h3>حذف بياناتك الشخصية</h3>
                <p>لديك الحق في حذف أو طلب مساعدتنا في حذف البيانات الشخصية التي قد جمعناها عنك.</p>
                <p>قد تمنح خدمتنا لك القدرة على حذف معلومات معينة عنك من داخل الخدمة.</p>
                <p>يمكنك تحديث أو تعديل أو حذف معلوماتك في أي وقت عن طريق تسجيل الدخول إلى حسابك، إذا كان لديك حساب، وزيارة قسم إعدادات الحساب الذي يتيح لك إدارة معلوماتك الشخصية. يمكنك أيضًا الاتصال بنا لطلب الوصول إلى معلوماتك، أو تصحيحها، أو حذف أي معلومات شخصية قد قدمتها لنا.</p>
                <p>يرجى ملاحظة، ومع ذلك، أنه قد نحتاج إلى الاحتفاظ ببعض المعلومات عندما يكون لدينا التزام قانوني أو أساس قانوني للقيام بذلك.</p>
                <h3>الكشف عن بياناتك الشخصية</h3>
                <h4>عمليات النقل التجارية</h4>
                <p>إذا كانت الشركة مشاركة في دمج، أو اقتناء، أو بيع الأصول، فإن بياناتك الشخصية يمكن نقلها. سنقدم إشعارًا قبل نقل بياناتك الشخصية وتخضع لسياسة خصوصية مختلفة.</p>
                <h4>إنفاذ القانون</h4>
                <p>في ظروف معينة، قد تكون مطالبة الشركة بالكشف عن بياناتك الشخصية إذا كان ذلك مطلوبًا قانونيًا أو استجابة لطلبات صالحة من السلطات العامة (مثل المحكمة أو جهاة حكومية).</p>
                <h4>متطلبات قانونية أخرى</h4>
                <p>قد تقوم الشركة بالكشف عن بياناتك الشخصية بنية حسنة واعتقادًا في أن مثل هذا الإجراء ضروري للقيام به:</p>
                <ul>
                    <li>الامتثال لالتزام قانوني</li>
                    <li>حماية ودفاع عن حقوق الشركة أو ممتلكاتها</li>
                    <li>منع أو التحقيق في الأنشطة الخاطئة المحتملة فيما يتعلق بالخدمة</li>
                    <li>حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور</li>
                    <li>الحماية من المسؤولية القانونية</li>
                </ul>
                <h3>أمان بياناتك الشخصية</h3>
                <p>أمان بياناتك الشخصية أمر مهم بالنسبة لنا، ولكن عليك أن تتذكر أنه لا يمكن ضمان 100٪ لأي طريقة لنقل البيانات عبر الإنترنت أو لتخزينها إلكترونيًا. بينما نسعى جاهدين لاستخدام وسائل تجارية قابلة للقبول لحماية بياناتك الشخصية، إلا أننا لا نستطيع ضمان أمانها المطلق.</p>
                <h2>خصوصية الأطفال</h2>
                <p>لا يستهدف خدمتنا أي شخص دون سن 13 عامًا. نحن لا نقوم بجمع المعلومات التي يمكن التعرف عليها شخصيًا من أي شخص دون سن 13 عامًا عن علم. إذا كنت والدي أو وصي على طفل وكنت على علم بأن طفلك قد قدم لنا معلومات شخصية، يرجى الاتصال بنا. إذا أصبح لدينا علم بأننا قمنا بجمع معلومات شخصية من أي شخص دون سن 13 عامًا بدون التحقق من موافقة الوالدين، نتخذ خطوات لإزالة تلك المعلومات من خوادمنا.</p>
                <p>إذا كنا بحاجة إلى الاعتماد على موافقة كأساس قانوني لمعالجة معلوماتك وكان بلدك يتطلب موافقة من ولي الأمر، قد نحتاج إلى موافقة ولي الأمر قبل جمع واستخدام تلك المعلومات.</p>
                <h2>روابط إلى مواقع الويب الأخرى</h2>
                <p>قد تحتوي خدمتنا على روابط إلى مواقع ويب أخرى لا تديرها نحن. إذا قمت بالنقر على رابط طرف ثالث، ستتم توجيهك إلى موقع طرف ثالث ذلك. ننصح بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.</p>
                <p>ليس لدينا أي تحكم على المحتوى أو سياسات الخصوصية أو الممارسات على مواقع الويب أو الخدمات من طرف ثالث.</p>
                <h2>تغييرات في سياسة الخصوصية هذه</h2>
                <p>قد نقوم بتحديث سياسة الخصوصية لدينا من وقت لآخر. سنخطرك بأي تغييرات من خلال نشر السياسة الجديدة على هذه الصفحة.</p>
                <p>سنخبرك أيضًا عبر البريد الإلكتروني و/أو إشعار بارز على خدمتنا قبل أن تصبح التغييرات سارية المفعول وسنقوم بتحديث تاريخ "آخر تحديث" في أعلى هذه الصفحة.</p>
                <p>ننصحك بمراجعة هذه السياسة بشكل دوري لأي تغييرات. تصبح تلك التغييرات سارية المفعول عند نشرها على هذه الصفحة.</p>
                <h2>اتصل بنا</h2>
                <p>إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا:</p>
                <ul>
                    <li>
                        <p>عبر البريد الإلكتروني: info@integraegy.com</p>
                    </li>
                    <li>
                        <p>عبر رقم الهاتف: 201099919020</p>
                    </li>
                </ul>

            </> : <>
                <h1>Privacy Policy</h1>
                <p>Last updated: October 29, 2023</p>
                <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Privacy Policy Generator</a>.</p>
                <h2>Interpretation and Definitions</h2>
                <h3>Interpretation</h3>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h3>Definitions</h3>
                <p>For the purposes of this Privacy Policy:</p>
                <ul>
                    <li>
                        <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                    </li>
                    <li>
                        <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                    </li>
                    <li>
                        <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Integra for Building and IT Solutions, New Cairo, Egypt.</p>
                    </li>
                    <li>
                        <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
                    </li>
                    <li>
                        <p><strong>Country</strong> refers to:  Egypt</p>
                    </li>
                    <li>
                        <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                    </li>
                    <li>
                        <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                    </li>
                    <li>
                        <p><strong>Service</strong> refers to the Website.</p>
                    </li>
                    <li>
                        <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                    </li>
                    <li>
                        <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                    </li>
                    <li>
                        <p><strong>Website</strong> refers to Integra E-Commerce, accessible from <a className="a2" href="https://www.integraegy.com" rel="external nofollow noopener" target="_blank">https://www.integraegy.com</a></p>
                    </li>
                    <li>
                        <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                    </li>
                </ul>
                <h2>Collecting and Using Your Personal Data</h2>
                <h3>Types of Data Collected</h3>
                <h4>Personal Data</h4>
                <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                <ul>
                    <li>
                        <p>Email address</p>
                    </li>
                    <li>
                        <p>First name and last name</p>
                    </li>
                    <li>
                        <p>Phone number</p>
                    </li>
                    <li>
                        <p>Address, State, Province, ZIP/Postal code, City</p>
                    </li>
                    <li>
                        <p>Usage Data</p>
                    </li>
                </ul>
                <h4>Usage Data</h4>
                <p>Usage Data is collected automatically when using the Service.</p>
                <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                <h4>Tracking Technologies and Cookies</h4>
                <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                <ul>
                    <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                    <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                </ul>
                <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the <a href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking" target="_blank">Privacy Policies website</a> article.</p>
                <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                <ul>
                    <li>
                        <p><strong>Necessary / Essential Cookies</strong></p>
                        <p>Type: Session Cookies</p>
                        <p>Administered by: Us</p>
                        <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                    </li>
                    <li>
                        <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                    </li>
                    <li>
                        <p><strong>Functionality Cookies</strong></p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                    </li>
                </ul>
                <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
                <h3>Use of Your Personal Data</h3>
                <p>The Company may use Personal Data for the following purposes:</p>
                <ul>
                    <li>
                        <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
                    </li>
                    <li>
                        <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                    </li>
                    <li>
                        <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                    </li>
                    <li>
                        <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                    </li>
                    <li>
                        <p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
                    </li>
                    <li>
                        <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                    </li>
                    <li>
                        <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                    </li>
                    <li>
                        <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
                    </li>
                </ul>
                <p>We may share Your personal information in the following situations:</p>
                <ul>
                    <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
                    <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                    <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                    <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                    <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                    <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
                </ul>
                <h3>Retention of Your Personal Data</h3>
                <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                <h3>Transfer of Your Personal Data</h3>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                <h3>Delete Your Personal Data</h3>
                <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
                <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
                <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
                <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
                <h3>Disclosure of Your Personal Data</h3>
                <h4>Business Transactions</h4>
                <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <h4>Law enforcement</h4>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h4>Other legal requirements</h4>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>Comply with a legal obligation</li>
                    <li>Protect and defend the rights or property of the Company</li>
                    <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>Protect the personal safety of Users of the Service or the public</li>
                    <li>Protect against legal liability</li>
                </ul>
                <h3>Security of Your Personal Data</h3>
                <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                <h2>Children's Privacy</h2>
                <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                <h2>Links to Other Websites</h2>
                <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <h2>Changes to this Privacy Policy</h2>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                <ul>
                    <li>
                        <p>By email: info@integraegy.com</p>
                    </li>
                    <li>
                        <p>By phone number: 201099919020</p>
                    </li>
                </ul>
            </>
            }
        </Container >
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Refund);
