import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Dialog, Pagination } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getSubcategory } from '../../app/store/actions/commonActions';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import { StoreHome } from '../Store';
import { useEffect } from 'react';
import { Inbox, MoreHoriz } from '@mui/icons-material';
import ProductCard from '../../app/components/ProductCard';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { getFavourites } from '../../app/store/actions/usersActions';

export const Subcategory = ({ user, favourites, getFavourites, getSubcategory, isLoading, subcategory, products }) => {

    const navigate = useNavigate()
    const subcategoryId = useParams().id
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();
    const [filters, setFilters] = useState({
        page: 1,
        limit: 100
    })

    const { page, limit } = filters

    const handlePageChange = (event, value) => {
        setFilters({ ...filters, page: value })
    }

    useEffect(() => {
        getSubcategory({ ...filters, subcategoryId, locale: currentLocale })
    }, [filters, subcategoryId, currentLocale])

    React.useEffect(() => {
        if(user && user.type === "User"){
            getFavourites({})
        }
    }, [user])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10 }} >
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ minWidth: "100vw", minHeight: isMobile ? "20vh" : "40vh", mt: -2, mb: "74px", backgroundImage: "url(https://integraecommerce.s3.eu-west-3.amazonaws.com/logos/background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center', position: "relative" }}>
                    <Avatar className="position-absolute top-100 start-50 translate-middle" alt={subcategory && subcategory[`name_${currentLocale}`]} src={subcategory?.category?.image} sx={{ width: 128, height: 128, background: "var(--terColor)", padding: 2 }} />
                </Box>
                <Typography fontWeight="bold">{subcategory && subcategory[`name_${currentLocale}`]}</Typography>
            </Box>
            <BrowserView>
                <Box sx={{ flexDirection: "column" }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("products.products")}</Typography>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }}>
                        {products?.docs?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3}>
                                <ProductCard product={product} navigate={navigate} />
                            </Grid>
                        })}
                        {products?.docs?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </BrowserView>
            <MobileView>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Typography fontWeight="bold" fontSize={isMobile ? 20 : 30}>{t("products.products")}</Typography>
                    <Grid container spacing={1}>
                        {products?.docs?.length > 0 &&
                            <>
                                {products?.docs?.map((product) => {
                                    return (
                                        <Grid item xs={6}>
                                            <ProductCard product={product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {products?.docs?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </MobileView>
            <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                <Pagination count={products?.pages || 1} page={page} onChange={handlePageChange} />
            </Box>

        </Box>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    favourites: state?.records?.favourites,
    subcategory: state?.records?.subcategory,
    products: state?.records?.products,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getSubcategory, getFavourites };

export default connect(mapStateToProps, mapDispatchToProps)(Subcategory);