import { Email, ExpandMore, Facebook, Phone, Send, Upload, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, right_flex_box } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../../app/config";
import { uploadFile } from "react-s3";
import { orderPriceList } from "../store/actions/usersActions";
import moment from "moment";

function OrderCard({ order, navigate, user }) {

    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();

    const getColor = () => {
        switch(order.status){
            case "Submitted":
            case "Replied": return "gray"
            case "Confirmed": return "blue" 
            case "Preparing": 
            case "Delivery": return "yellow" 
            case "Delivered": return "green"
            case "Cancelled": return "red"
            default: return "white"
        }
    }

    const handleNavigate = () => {
        if(user?.type === "Super") {
            navigate(`/super/orders/${order._id}`)
        } else {
            navigate(`/user/orders/${order._id}`)
        }
    }
    return (
        <Badge badgeContent={t(`status.${order.status}`)} color={getColor()} sx={{...(getColor() !== "white" ? {color: "white !important"} : {})}} >
            <Card onClick={handleNavigate} sx={{ borderRadius: 5, padding: 1, cursor: "pointer" }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14, fontWeight: "100", color: "gray" }} gutterBottom>
                        {t("order.date")} {moment(order.date).locale(currentLocale).format("lll")}
                    </Typography>
                    <Typography sx={{ fontSize: 20 }}>
                        {t("order.user")}: {order.user?.name || order.userInfo?.name}
                    </Typography>
                    <Typography sx={{ fontSize: 18 }}>
                        {t("order.value")}: {order.price || t("order.no_price")}
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: "100", color: "gray" }}>
                        {t("order.order_num", { num: order?._id })}
                    </Typography>
                </CardContent>
            </Card>
        </Badge>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);