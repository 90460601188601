import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Stack, Pagination, CardMedia, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import Logo from "../../app/components/integra-logo.png"
import { Carousel, Image } from 'antd';
import Newsreel from '../../app/components/Newsreel';
import Cookies from 'js-cookie';
import { getBrands, getCategories, getProducts } from '../../app/store/actions/commonActions';
import { Inbox, MoreHoriz, Swipe } from '@mui/icons-material';
import { useEffect } from 'react';
import QuotationProdCard from '../../app/components/QuotationProdCard';
import ProductCard from '../../app/components/ProductCard';
import { useState } from 'react';

export const StoreHome = ({ user, getBrands, getCategories, getProducts, brands, categories, isLoading, quotationb2c, products, productsPagination, featuredProducts }) => {

    const navigate = useNavigate()
    const eventId = useParams().id
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";
    const [items, setItems] = React.useState([])
    const [productFilters, setProductFilters] = useState({
        page: 1,
        limit: 20,
        method: "ByPiece",
        type: "B2C"
    })
    const { page, limit, method, type } = productFilters

    React.useEffect(() => {
        getBrands({ page: 1, limit: 100 })
        getCategories({})
        getProducts({ page: 1, limit: 100, method: "FileUpload", type: "B2B" }, "quotationb2bProducts")
        getProducts({ page: 1, limit: 100, method: "FileUpload", type: "B2C" }, "quotationb2cProducts")
        getProducts(productFilters, "products")
        getProducts({ page: 1, limit: 500, method: "ByPiece", type: "B2C", featured: true }, "featuredProducts")
    }, [])

    React.useEffect(() => {
        getProducts(productFilters, "products")
    }, [productFilters])

    const handlePageChange = (event, value) => {
        setProductFilters({ ...productFilters, page: value })
    }

    useEffect(() => {
        setItems(brands?.map((brand, idx) => {
            return (
                <Card sx={{ cursor: "pointer", padding: 2 }} key={idx}>
                    <Image width={"80px"} onClick={() => navigate(`/brand/${brand._id}`)} preview={false} src={brand.logo} />
                </Card>
            )
        }))
    }, [brands])

    const [selectedCategory, setCategory] = React.useState(undefined)

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ ...centered_flex_box, mt: 2 }}>
                    {brands && items && <Newsreel itemSpacing="10px" scrollSpeed={15} isLTR={currentLocale === "en"} items={[...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items]} />}
                </Box>
                <Box sx={{ flexDirection: "column", mt: isMobile ? 2 : 5 }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("menu.categories")}</Typography>
                    {categories &&
                        <Grid container spacing={2}>
                            {categories.map((category) => {
                                return (
                                    <Grid key={category._id} item xs={isMobile ? 6 : 3}>
                                        <Card onClick={() => navigate(`/category/${category._id}`)} sx={{ ...centered_flex_box, flexDirection: "column", cursor: "pointer", height: 150 }}>
                                            <CardContent sx={{ ...centered_flex_box, flexDirection: "column" }}>
                                                <Image src={category.image} width="25%" preview={false} />
                                            </CardContent>
                                            <CardContent>
                                                <Typography textAlign="center" variant="body2">{category[`name_${currentLocale}`]}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>}
                </Box>
            </Box>
            <Box sx={{ flexDirection: "column" }}>
                <Typography fontSize={isMobile ? 20 : 30}>{t("products.quotation_products")}</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    {quotationb2c?.map((product) => {
                        return <QuotationProdCard product={product} navigate={navigate} />
                    })}
                </Box>
            </Box>


            <BrowserView>
                <Box sx={{ flexDirection: "column" }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("products.featured_products")}</Typography>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={6}>
                        {featuredProducts?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3} sx={{ ...centered_flex_box }}>
                                <ProductCard product={product} navigate={navigate} />
                            </Grid>
                        })}
                        {featuredProducts?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box sx={{ flexDirection: "column" }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("products.products")}</Typography>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={6}>
                        {products?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3} sx={{ ...centered_flex_box }}>
                                <ProductCard product={product} navigate={navigate} />
                            </Grid>
                        })}
                        {products?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </BrowserView>
            <MobileView>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Box sx={{ ...centered_flex_box, justifyContent: "space-between" }}>
                        <Typography fontWeight="bold" fontSize={20}>{t("products.featured_products")}</Typography><Swipe />
                    </Box>
                    <Grid container spacing={1} style={{ display: "flex", flexWrap: 'nowrap', overflowX: "scroll" }}>
                        {featuredProducts?.length > 0 &&
                            <>
                                {featuredProducts?.map((product) => {
                                    return (
                                        <Grid item xs={6}>
                                            <ProductCard product={product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {featuredProducts?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Box sx={{ ...centered_flex_box, justifyContent: "space-between" }}>
                        <Typography fontWeight="bold" fontSize={20}>{t("products.products")}</Typography><Swipe />
                    </Box>
                    <Grid container spacing={1} style={{ display: "flex", flexWrap: 'nowrap', overflowX: "scroll" }}>
                        {products?.length > 0 &&
                            <>
                                {products?.map((product) => {
                                    return (
                                        <Grid item xs={12} key={product._id}>
                                            <ProductCard product={product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {products?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </MobileView>
            <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                <Pagination count={productsPagination?.pages || 1} page={page} onChange={handlePageChange} />
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading,
    brands: state?.records?.brands?.docs,
    categories: state?.records?.categories,
    quotationb2c: state?.records?.quotationb2cProducts?.products?.docs,
    products: state?.records?.products?.products?.docs,
    featuredProducts: state?.records?.featuredProducts?.products?.docs,
    productsPagination: state?.records?.products?.products
});

const mapDispatchToProps = { getBrands, getProducts, getCategories };

export default connect(mapStateToProps, mapDispatchToProps)(StoreHome);