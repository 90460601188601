import * as React from "react";
import {
    Container,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export const Refund = ({ }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";

    return (
        <Container sx={{ padding: 10 }}>
            {currentLocale === "ar" ? <>
                <h1><strong>سياسة إرجاع واسترداد الأموال في إنتجرا</strong></h1>

                <p>يمكن إرجاع العناصر التي تم شحنها من إنتجرا خلال 14 يومًا من استلام الشحنة في معظم الحالات. يمكن إرجاع العناصر التي تم استلامها تالفة أو معيبة أو مستخدمة أو ناقصة أو غير موصوفة خلال 30 يومًا من استلام الشحنة. بعض المنتجات قد تحمل سياسات أو متطلبات مختلفة مرتبطة بها.</p>

                <p>بعد أن يتلقى البريد السريع عنصرك، قد يستغرق الأمر أسبوعين لاستلام ومعالجة إرجاعك من قبلنا.</p>

                <p>قد يستغرق الأمر أسبوعين للطلبات التي تم شراؤها من بائعي السوق. بعد معالجة الإرجاع، قد يستغرق 5 إلى 7 أيام عمل لظهور المبلغ المسترد على بيان بطاقتك الائتمانية.</p>

                <strong>ملاحظة:</strong> لن نقبل إرجاع أي عنصر يتم إرساله بشكل خاطئ إلينا. وهذا يشمل العناصر التي لم تتم شراؤها أصلاً من إنتجرا. في حالة استلامنا مثل هذه العناصر، لن نتمكن من إجراء إرجاع أو توفير استرداد أو تنازل آخر بخصوص مثل هذه العناصر.

                <p>يمكننا فقط تقديم استرداد بخصوص العناصر الصحيحة التي تم إرجاعها وفقًا لسياستنا. لا يمكن شحن أو إرجاع أي عنصر تقوم بإرساله أو إرجاعه إلى إنتجرا.</p>
                <h2>العناصر في إنتجرا غير القابلة للإرجاع</h2>

                <p>العناصر التالية غير قابلة للإرجاع بسبب طبيعة المنتج الاستهلاكي. ومع ذلك، في حالة استلام عنصر تالف أو معيب أو مختلف عن العنصر المطلوب منك، سنقدم استردادًا كاملاً أو استبدالًا مجانيًا حسب الاقتضاء.</p>

                <li>الدهانات</li>
                <li>الإسمنت</li>
                <li>الكوابل الكهربائية</li>
                <li>العناصر المطلوبة بناءً على طلب خاص</li>
                
                <br />
                <p>لمزيد من المعلومات، يرجى الاتصال بخدمة العملاء لدينا:</p>
                <li><a className="a2" href="mailto:info@integraegy.com"><strong>info@integraegy.com</strong></a></li>
                <li><a className="a2" href="tel:201099919020"><strong>201099919020</strong></a></li>
                <br />

                <h2>سياسة الاسترداد</h2>

                <p>بعد استلام إرجاعك وفحص حالة العنصر الخاص بك، سنقوم بمعالجة إرجاعك. يرجى السماح بمدة لا تقل عن سبعة (7) أيام من استلام عنصرك لمعالجة الإرجاع. قد تحتاج الاستردادات إلى 1-2 دورة فوترة للظهور على بيان بطاقتك الائتمانية، وذلك حسب شركة بطاقتك الائتمانية.</p>
            </>
                : <>
                    <h1><strong>Integra Return & Refund Policies</strong></h1>

                    <p>Items shipped from Integra can be returned within 14 days of receipt of shipment in most cases. Items that were received damaged, defective, used, missing parts, or not as described could be returned within 30 days of receipt of shipment. Some products have different policies or requirements associated with them.</p>

                    <p>After the courier has received your item, it can take up to two weeks for us to receive and process your return.</p>

                    <p>It may take up to two weeks for Marketplace Seller orders. After the return is processed it may take 5 to 7 business days for the refund to show on your payment card statement.</p>

                    <strong>Note:</strong> We will not accept the return of any item which is wrongfully sent to us. This includes items that were not originally purchased from Integra. In the event we receive such items, we are unable to return or provide a refund or other concession in respect of such items.

                    <p>We can only provide a refund in respect of correct items which are returned in accordance with our policies. Any item which you send or return to Integra cannot be shipped or otherwise returned to you.</p>
                    <h2>Integra Non-Returnable Items</h2>

                    <p>The following items are non-returnable due to the consumable nature of the product. However, in the unlikely event of the damaged, defective or different item being delivered to you, we will provide a full refund or free replacement as applicable.</p>

                    <li>Paints</li>
                    <li>Cement</li>
                    <li>Electrical Cables</li>
                    <li>Special Order Items</li>
                    <br />
                    <p>For more information please contact our customer service:</p>
                    <li><a className="a2" href="mailto:info@integraegy.com"><strong>info@integraegy.com</strong></a></li>
                    <li><a className="a2" href="tel:201099919020"><strong>201099919020</strong></a></li>
                    <br />

                    <h2>Refund Policy</h2>

                    <p>After receiving your return and inspecting the condition of your item, we will process your return. Please allow at least seven (7) days from the receipt of your item to process your return. Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company.</p>
                </>}
        </Container>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Refund);
