import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { AdminOrders, AdminSingleOrder, Analytics, BrandHome, Cart, Category, CheckTransaction, ComingSoon, Contact, Favourites, ForgotPassword, Home, IFramePayment, Login, Privacy, Product, Profile, Refund, ResetPassword, SearchResults, StoreHome, Subcategory, SuperProfile, Terms, UserOrders, UserSingleOrder, Verify } from "./Pages";
import ProtectedRoute from "./ProtectedRoute";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/reset-password/:token" exact element={<ResetPassword />} />
      <Route path="/forgot-password" exact element={<ForgotPassword />} />
      <Route path="/store" exact element={<StoreHome />} />
      <Route path="/store/results/:search" exact element={<SearchResults />} />
      <Route path="/brand/:id" exact element={<BrandHome />} />
      <Route path="/category/:id" exact element={<Category />} />
      <Route path="/subcategory/:id" exact element={<Subcategory />} />
      <Route path="/product/:id" exact element={<Product />} />
      <Route path="/about" exact element={<ComingSoon />} />
      <Route path="/terms" exact element={<Terms />} />
      <Route path="/policy/refund" exact element={<Refund />} />
      <Route path="/policy/privacy" exact element={<Privacy />} />
      <Route path="/account" exact element={<ComingSoon />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route path="/verify/:token" exact element={<Verify />} />
      <Route exact element={<ProtectedRoute allowed={["Super"]} />}>
        <Route path="/super/orders/:orderId" exact element={<AdminSingleOrder />} />
        <Route path="/super/orders" exact element={<AdminOrders />} />
        <Route path="/super" exact element={<ComingSoon />} />
        <Route path="/super/account" exact element={<SuperProfile />} />
        <Route path="/super/analytics" exact element={<Analytics />} />
      </Route>
      <Route path="/transaction/response" exact element={<CheckTransaction />} />
      <Route exact element={<ProtectedRoute allowed={["User"]} />}>
        <Route path="/user/orders/:orderId" exact element={<UserSingleOrder />} />
        <Route path="/user/orders" exact element={<UserOrders />} />
        <Route path="/cart" exact element={<Cart />} />
        <Route path="/payment" exact element={<IFramePayment />} />
        <Route path="/user" exact element={<ComingSoon />} />
        <Route path="/user/account" exact element={<Profile />} />
        <Route path="/user/favourites" exact element={<Favourites />} />
      </Route>
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
