//Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN = "LOGIN";

//Cart 
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";
export const CART = "CART";

//Logout
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT = "LOGOUT";

//Change user data
export const CHANGE_SUCCESS = "CHANGE_SUCCESS";
export const CHANGE_FAIL = "CHANGE_FAIL";
export const CHANGE = "CHANGE";

//General Purpose
export const WAIT_SUCCESS = "WAIT_SUCCESS";
export const WAIT_FAIL = "WAIT_FAIL";
export const WAIT = "WAIT";

export const FETCH_RECORDS_SUCCESS = "FETCH_RECORDS_SUCCESS";
export const FETCH_RECORDS_FAIL = "FETCH_RECORDS_FAIL";
export const FETCH_RECORDS = "FETCH_RECORDS";

export const FAVOURITE_SUCCESS = "FAVOURITE_SUCCESS";
export const FAVOURITE_FAIL = "FAVOURITE_FAIL";
export const FAVOURITE = "FAVOURITE";