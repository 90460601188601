import * as React from 'react';
import { Typography, Table, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Pagination, ToggleButtonGroup, ToggleButton, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainDateInput, MainInput, centered_flex_box, main_button, right_flex_box } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import Newsreel from '../../app/components/Newsreel';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getCategoriesSales, getMethodsSales, getProductsSales } from '../../app/store/actions/superActions';
import { useState } from 'react';
import OrderCard from '../../app/components/OrderCard';
import { DashboardCustomize, ExpandMore, Filter, Filter1, FilterAlt, Inbox, TableChart } from '@mui/icons-material';
import moment from 'moment/moment';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

export const Analytics = ({ getProductsSales, getCategoriesSales, getMethodsSales, data, totalSales, isLoading }) => {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";
    const COLORS = ['var(--secColor)', 'var(--secColor2)', 'var(--secColor3)', 'var(--terColor2)'];
    const [filtersOpen, setFiltersOpen] = useState(false);

    const initialFilters = {
        type: "products",
        month: undefined
    }
    const [filters, setFilters] = useState(initialFilters)
    const { month, type } = filters

    useEffect(() => {
        switch (type) {
            case "products": getProductsSales({ month }); break;
            case "categories": getCategoriesSales({ month }); break;
            case "methods": getMethodsSales({ month }); break;
            default: getProductsSales({ month }); break;
        }
    }, [filters])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, width: "100vw", height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10, paddingTop: isMobile ? 0 : 10 }}>
            <Box mb={2}>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography sx={{ fontWeight: "bold", fontSize: isMobile ? 20 : 25 }}>Analytics for {type}{month ? `in ${month}` : ""}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ ...right_flex_box }}>
                        <IconButton onClick={() => setFiltersOpen(!filtersOpen)}><FilterAlt /></IconButton>
                    </Grid>
                </Grid>

                {filtersOpen && <Card sx={{ padding: 2, margin: 1 }}>
                    <MainInput
                        margin="normal"
                        fullWidth
                        id="type"
                        label={t("order.type")}
                        name="type"
                        autoFocus
                        select
                        value={type}
                        onChange={({ target }) => setFilters({ ...filters, type: target.value })}
                    >
                        <MenuItem value={"products"}>
                            <Typography>Products</Typography>
                        </MenuItem>
                        <MenuItem value={"categories"}>
                            <Typography>Categories</Typography>
                        </MenuItem>
                        <MenuItem value={"methods"}>
                            <Typography>Payment Methods</Typography>
                        </MenuItem>
                    </MainInput>

                    <br />

                    <Box sx={{ ...centered_flex_box, marginY: 2 }}>
                        <Button sx={{ ...main_button }} onClick={() => setFilters(initialFilters)}>{t("clear_filters")}</Button>
                    </Box>
                </Card>}
            </Box>
            <Box>
                {type === "products" && data !== undefined && data.length > 0 && data[0].product !== undefined && (
                    <TableContainer>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">SKU</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Image</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Name</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Quantity</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Sales</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((product) => (
                                    <TableRow
                                        key={product.product._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                                        onClick={() => (navigate(`/product/${product.product._id}`))}
                                    >
                                        <TableCell align="center">{product.product.sku}</TableCell>
                                        <TableCell align="center"><Image width={50} height={50} src={product.product.image || product.product.variants[0].image} /></TableCell>
                                        <TableCell align="center">{product.product.name_en}</TableCell>
                                        <TableCell align="center">{product.totalAmount}</TableCell>
                                        <TableCell align="center">{product.totalRevenue.toFixed(2)} EGP</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {type === "categories" && data !== undefined && data.length > 0 && data[0].category !== undefined && (
                    <TableContainer>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Name</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Sales</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((category) => (
                                    <TableRow
                                        key={category.category._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                                        onClick={() => (navigate(`/category/${category.category._id}`))}
                                    >
                                        <TableCell align="center">{category.category.name_en}</TableCell>
                                        <TableCell align="center">{category.totalRevenue.toFixed(2)} EGP</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {type === "methods" && data !== undefined && data.length > 0 && data[0].method !== undefined && (
                    <TableContainer>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Method</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Revenue</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((method, idx) => (
                                    <TableRow
                                        key={idx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{method.method}</TableCell>
                                        <TableCell align="center">{method.totalRevenue.toFixed(2)} EGP</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <hr />
                <Typography fontWeight="bold" fontSize={30}>Charts</Typography>
                <Box sx={{ ...centered_flex_box, padding: 1, flexDirection: "column" }}>
                    <Typography fontWeight="bold" fontSize={24}>By Sales Revenue</Typography>
                    <Typography fontSize={20}>Total Revenue: {totalSales?.toFixed(2)} EGP</Typography>
                    {type === "products" && data !== undefined && data.length > 0 && data[0].product !== undefined && (
                        <PieChart width={isMobile ? 250 : 400} height={isMobile ? 250 : 400}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={data.map((product) => { return { name: product.product.name_en, value: parseInt(product.totalRevenue) } })}
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                outerRadius={isMobile ? 80 : 100}
                                label
                                fill="var(--secColor)"
                                paddingAngle={5}
                            >
                                {data.map((product) => { return { name: product.product.name_en, value: parseInt(product.totalRevenue) } }).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    )}
                    {type === "categories" && data !== undefined && data.length > 0 && data[0].category !== undefined && (
                        <PieChart width={isMobile ? 250 : 400} height={isMobile ? 250 : 400}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={data.map((category) => { return { name: category.category.name_en, value: parseInt(category.totalRevenue) } })}
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                outerRadius={isMobile ? 80 : 100}
                                label
                                fill="var(--secColor)"
                                paddingAngle={5}
                            >
                                {data.map((category) => { return { name: category.category.name_en, value: parseInt(category.totalRevenue) } }).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    )}
                    {type === "methods" && data !== undefined && data.length > 0 && data[0].method !== undefined && (
                        <PieChart width={isMobile ? 250 : 400} height={isMobile ? 250 : 400}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={data.map((method) => { return { name: method.method, value: parseInt(method.totalRevenue) } })}
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                outerRadius={isMobile ? 80 : 100}
                                label
                                fill="var(--secColor)"
                                paddingAngle={5}
                            >
                                {data.map((method) => { return { name: method.method, value: parseInt(method.totalRevenue) } }).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    )}
                    {type === "products" && data !== undefined && data.length > 0 && data[0].product !== undefined && (
                        <>
                            <Typography fontWeight="bold" fontSize={24}>By Sales Quantity</Typography>
                            <PieChart width={isMobile ? 250 : 400} height={isMobile ? 250 : 400}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={false}
                                    data={data.map((product) => { return { name: product.product.name_en, value: parseInt(product.totalAmount) } })}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={40}
                                    outerRadius={isMobile ? 80 : 100}
                                    label
                                    fill="var(--secColor)"
                                    paddingAngle={5}
                                >
                                    {data.map((product) => { return { name: product.product.name_en, value: parseInt(product.totalRevenue) } }).map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </>
                    )}
                </Box>
            </Box>
        </Box >
    );
}

const mapStateToProps = (state) => ({
    data: state?.records?.analyticsData,
    totalSales: state?.records?.totalSales,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getProductsSales, getCategoriesSales, getMethodsSales };

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);