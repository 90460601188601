import { Add, AddShoppingCart, Email, ExpandMore, Facebook, Favorite, FavoriteBorder, Info, Notifications, Phone, Remove, Send, ShoppingCart, Upload, Watch, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box } from "./Styles";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../config";
import { uploadFile } from "react-s3";
import { orderPriceList, toggleFavourite } from "../store/actions/usersActions";

function ProductCard({ product, navigate, user, orderPriceList, favourites, toggleFavourite, isLoading }) {
    window.Buffer = window.Buffer || require("buffer").Buffer;

    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();
    const [quantity, setQuantity] = useState(0)

    const increment = () => {
        if (quantity === 99) return
        if (quantity === 0) {
            setQuantity(product.minOrder)
            return
        }
        setQuantity(quantity + product.batchSize)
    }

    const decrement = () => {
        if (quantity === 0) return
        if (quantity === product.minOrder) {
            setQuantity(0)
            return
        }
        setQuantity(quantity - product.batchSize)
    }

    const [dialogOpen, setDialogOpen] = useState(false)
    const handleOpen = () => {
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleAddToCart = () => {
        if (!user) {
            notification.info({ message: t("must_login"), style: { marginTop: "10vh" } })
            navigate("/login")
        } else {

        }
    }

    const handleFavourite = () => {
        toggleFavourite({ productId: product._id })
    }

    return (
        <>
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                <Box sx={{ padding: 2 }}>
                    <Typography fontSize={18} >{product[`name_${currentLocale}`]}</Typography>
                    <Typography fontSize={11} color="grey" >{product.sku}</Typography>
                    <Box sx={{ ...centered_flex_box }}>
                        <Image preview={false} src={product.image || product.variants[0].image} height={isMobile ? 150 : 200} style={{ height: "100%", width: "auto" }} />
                    </Box>
                    {product.description_en && <Box>
                        {product.description_en.about && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.about")} :</Typography>
                            <Typography fontSize={isMobile ? 13 : 15} >{product[`description_${currentLocale}`]?.about}</Typography>
                        </>}
                        {product.description_en.composition && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.composition")} :</Typography>
                            <Typography fontSize={isMobile ? 13 : 15} >{product[`description_${currentLocale}`]?.composition}</Typography>
                        </>}
                        {product.description_en.features && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.features")} :</Typography>
                            {product[`description_${currentLocale}`]?.features.map((feature) => { return <Typography fontSize={isMobile ? 13 : 15} >• {feature}</Typography> })}
                        </>}
                        {product.description_en.applications && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.applications")} :</Typography>
                            {product[`description_${currentLocale}`]?.applications.map((application) => { return <Typography fontSize={isMobile ? 13 : 15} >• {application}</Typography> })}
                        </>}
                    </Box>}
                    {product.catalog && <Box sx={{ height: "80vh", p: 2 }}>
                        <iframe title={product[`name_${currentLocale}`]} src={product.catalog} height={"100%"} width={"100%"}></iframe>
                    </Box>}
                </Box>
            </Dialog>
            <BrowserView>
                <Card sx={{ borderRadius: 5, padding: 2, mx: 1, my: 1, height: 600, width: 300, display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ ...centered_flex_box, justifyContent: "space-between", margin: 0, padding: 0 }}>
                        {favourites && (favourites.filter((fav) => fav.product._id === product._id).length === 0 ?
                            <IconButton onClick={handleFavourite}><FavoriteBorder /></IconButton> :
                            <IconButton onClick={handleFavourite}><Favorite sx={{ color: "var(--secColor)" }} /></IconButton>)
                        }
                        {product?.discount && <Chip color="success" label={`${product.discount.value * 100}%`} />}
                    </CardContent>
                    <CardContent sx={{ ...centered_flex_box }}>
                        <Image preview={false} src={product.image || product.variants[0].image} height={160} style={{ height: "100%", width: "auto" }} />
                    </CardContent>
                    <CardContent>
                        <Typography fontSize={18} >{product[`name_${currentLocale}`]}</Typography>
                        <Typography fontSize={11} color="grey" >{product.sku}</Typography>
                    </CardContent>
                    {!product?.discount && <CardContent>
                        <Typography fontWeight="bold" fontSize={20} >{product.variants.length > 0 ? `${t("product.starts_from", { num: product.variants[0].price })} ${t("egp")}` : (product.price === 0 ? t("product.no_price") : `${product.price} ${t("egp")}`)} </Typography>
                    </CardContent>}
                    {product?.discount && <CardContent>
                        <Typography sx={{ textDecorationLine: "line-through" }} fontWeight="bold" fontSize={18} >{product.variants.length > 0 ? t("product.starts_from", { num: product.variants[0].price }) : product.price} {t("egp")}</Typography>
                        <Typography sx={{ color: "green !important" }} fontWeight="bold" fontSize={20} >{(product.variants.length > 0 ? product.variants[0].price : product.price) * (1 - product.discount.value)} {t("egp")}</Typography>
                    </CardContent>}
                    <CardContent sx={{ marginTop: 'auto', padding: "0 !important" }}>
                        {product?.stock === 0 && <Typography color={"grey"} fontSize={11}>{t("out_of_stock")}</Typography>}
                        {product?.stock === 0 && <Button fullWidth sx={{ ...main_button }}>{t("notify_me")}</Button>}
                        {(product?.variants?.length > 0 || product?.colors?.length > 0) && <Button onClick={() => navigate(`/product/${product._id}`)} fullWidth sx={{ ...main_button }}>{t("view_options")}</Button>}
                        {product?.stock !== 0 && product?.variants?.length === 0 && product?.colors?.length === 0 && product.price !== 0 && <Button onClick={() => navigate(`/product/${product._id}`)} fullWidth sx={{ ...main_button }}>{t("add_to_cart")}</Button>}
                        {product.price === 0 && product?.variants?.length === 0 && product?.colors?.length === 0 && <Button onClick={() => navigate(`/product/${product._id}`)} fullWidth sx={{ ...main_button }}>{t("products.quotation_products")}</Button>}
                    </CardContent>
                </Card>
            </BrowserView>
            <MobileView>
                <Card sx={{ padding: 1, marginLeft: "auto", marginRight: "auto", my: 1, width: 160, height: 380, display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ ...centered_flex_box, justifyContent: "space-between", margin: 0, padding: 0 }}>
                        {favourites && (favourites.filter((fav) => fav.product._id === product._id).length === 0 ?
                            <IconButton onClick={handleFavourite}><FavoriteBorder /></IconButton> :
                            <IconButton onClick={handleFavourite}><Favorite sx={{ color: "var(--secColor)" }} /></IconButton>)
                        }
                        {product?.discount && <Chip color="success" label={`${product.discount.value * 100}%`} />}
                    </CardContent>
                    <div onClick={() => navigate(`/product/${product._id}`)}>
                        <CardContent sx={{ ...centered_flex_box, paddingBottom: "0 !important" }}>
                            <Image preview={false} src={product.image || product.variants[0].image} height={100} style={{ height: "100%", width: "auto" }} />
                        </CardContent>
                        <CardContent sx={{ paddingBottom: "0 !important" }}>
                            <Typography fontSize={15} >{product[`name_${currentLocale}`]}</Typography>
                        </CardContent>
                        <CardContent sx={{ paddingBottom: "0 !important" }}>
                            <Grid container>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                    {!product?.discount && <>
                                        <Typography fontWeight="bold" fontSize={13} >{product.variants.length > 0 ? `${t("product.starts_from", { num: product.variants[0].price })} ${t("egp")}` : (product.price === 0 ? t("product.no_price") : `${product.price} ${t("egp")}`)} </Typography>
                                    </>}
                                    {product?.discount && <>
                                        <Typography sx={{ textDecorationLine: "line-through" }} fontWeight="bold" fontSize={11} >{product.variants.length > 0 ? t("product.starts_from", { num: product.variants[0].price }) : product.price} {t("egp")}</Typography>
                                        <Typography sx={{ color: "green !important" }} fontWeight="bold" fontSize={13} >{(product.variants.length > 0 ? product.variants[0].price : product.price) * (1 - product.discount.value)} {t("egp")}</Typography>
                                    </>}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </div>
                    <CardContent sx={{ marginTop: 'auto', padding: "0 !important" }}>
                        {product?.stock === 0 && <Typography color={"grey"} fontSize={11}>{t("out_of_stock")}</Typography>}
                        {product?.stock === 0 && <Button fullWidth sx={{ ...main_button }}>{t("notify_me")}</Button>}
                        {(product?.variants?.length > 0 || product?.colors?.length > 0) && <Button onClick={() => navigate(`/product/${product._id}`)} fullWidth sx={{ ...main_button }}>{t("view_options")}</Button>}
                        {product?.stock !== 0 && product?.variants?.length === 0 && product?.colors?.length === 0 && product.price !== 0 && <Button onClick={() => navigate(`/product/${product._id}`)} fullWidth sx={{ ...main_button }}>{t("add_to_cart")}</Button>}
                        {product.price === 0 && product?.variants?.length === 0 && product?.colors?.length === 0 && <Button onClick={() => navigate(`/product/${product._id}`)} fullWidth sx={{ ...main_button }}>{t("products.quotation_products")}</Button>}
                    </CardContent>
                </Card>
            </MobileView>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.wait?.isLoading,
    favourites: state?.records?.favourites
});

const mapDispatchToProps = { orderPriceList, toggleFavourite };

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);