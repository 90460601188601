import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Dialog, Card, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainDateInput, MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { newsletter } from '../../app/store/actions/commonActions';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import { StoreHome } from '../Store';
import { useEffect } from 'react';
import { ChangeCircleOutlined, Map, MoreHoriz, Visibility } from '@mui/icons-material';
import { getOrder, editOrderStatus, sendPriceList } from '../../app/store/actions/superActions';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const SingleOrder = ({ getOrder, editOrderStatus, sendPriceList, isLoading, order }) => {

    const navigate = useNavigate()
    const orderId = useParams().orderId
    const { t, i18n } = useTranslation();
    const [detailsOpen, setDetailsOpen] = React.useState(false)
    const [statusOpen, setStatusOpen] = React.useState(false)
    const [expiry, setExpiry] = React.useState(moment().add(7, "days"))

    useEffect(() => {
        getOrder({ orderId })
    }, [])

    const handleSendPricelist = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const items = {}

        order?.items?.map((item) => {
            items[item._id] = {
                price: data.get(`price_${item._id}`)
            }
            return item
        })

        sendPriceList({ orderId, items, expiry })
        getOrder({ orderId })
    }

    const handleEditOrderStatus = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const status = data.get("status")
        if (order?.status !== status) {
            editOrderStatus({ orderId, status })
        }
        setStatusOpen(false);
    }

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
            <Dialog open={statusOpen} onClose={() => setStatusOpen(false)}>
                <Box component="form" onSubmit={handleEditOrderStatus} sx={{ padding: 2 }}>
                    <Typography fontSize={isMobile ? 25 : 30} fontWeight="bold">Change Order Status</Typography>
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id="status"
                        label="Status"
                        name="status"
                        autoFocus
                        select
                        defaultValue={"Preparing"}
                    >
                        {order?.type === "Quotation" && <MenuItem value={"Replied"}>
                            <Typography>Replied</Typography>
                        </MenuItem>}
                        <MenuItem value={"Confirmed"}>
                            <Typography>Confirmed</Typography>
                        </MenuItem>
                        <MenuItem value={"Preparing"}>
                            <Typography>Preparing</Typography>
                        </MenuItem>
                        <MenuItem value={"Delivery"}>
                            <Typography>Delivery</Typography>
                        </MenuItem>
                        <MenuItem value={"Delivered"}>
                            <Typography>Delivered</Typography>
                        </MenuItem>
                        <MenuItem value={"Cancelled"}>
                            <Typography>Cancelled</Typography>
                        </MenuItem>
                    </MainInput>
                    <Button type='submit' sx={{ ...main_button }} fullWidth>Update</Button>
                </Box>
            </Dialog>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ padding: isMobile ? 2 : 10, minWidth: "100vw", minHeight: isMobile ? "20vh" : "40vh", mt: -2, backgroundImage: "url(https://integraecommerce.s3.eu-west-3.amazonaws.com/logos/background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center', position: "relative" }}>
                    <Typography textAlign="center" fontWeight="bold">Order Id. {order?._id}</Typography>
                    <Typography textAlign="center" fontWeight="bold">Order No. {order?.paymobOrderId}</Typography>
                    <Typography textAlign="center" fontWeight="bold">Order Type: {order?.type}</Typography>
                </Box>
            </Box>
            {order?.type === "Quotation" ? (
                <Box sx={{ mt: 2 }}>
                    <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)}>
                        <Box sx={{ padding: 2 }}>
                            <Typography fontSize={isMobile ? 25 : 30} fontWeight="bold">Order Details</Typography>
                            <Typography fontSize={isMobile ? 15 : 20} textAlign="justify">{order?.orderDetails}</Typography>
                        </Box>
                    </Dialog>
                    <Typography fontSize={30} fontWeight="bold">Order Details</Typography>
                    {order?.file && <Button sx={{ ...main_button, mr: 1 }} href={order?.file.link} target='_blank'><Visibility sx={{ mr: 1 }} /> View File</Button>}
                    {order?.orderDetails && <Button onClick={() => setDetailsOpen(true)} sx={{ ...main_button, mr: 1 }}><Visibility sx={{ mr: 1 }} /> View Details</Button>}
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <Card sx={{ my: 2, padding: 2 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Product: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>{order?.product.name_en}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Status: {order?.status}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Button onClick={() => setStatusOpen(true)} size='small' sx={{ ...main_button }}><ChangeCircleOutlined sx={{ mr: 1 }} /> Change Status</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20} fontWeight="bold">User Info</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Name: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>{order?.user?.name || order?.userInfo?.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Email: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}><NavLink className="a2" to={`mailto:${order?.user?.email || order?.userInfo.email}`}>{order?.user?.email || order?.userInfo.email}</NavLink></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Mobile: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}><NavLink className="a2" to={`tel:${order?.user?.mobile || order?.userInfo.mobile}`}>{order?.user?.mobile || order?.userInfo.mobile}</NavLink></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Is a user?: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>{order?.user !== undefined ? "Yes" : "No"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8}>
                            <Box component="form" onSubmit={handleSendPricelist}>
                                <Card sx={{ my: 2, padding: 2 }}>
                                    {order?.items?.length > 0 && order?.items?.map((item) => {
                                        return (
                                            <Card key={item._id} sx={{ my: 2 }}>
                                                <CardContent>
                                                    <Grid container spacing={2} rowSpacing={2}>
                                                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                            <Image src={item.product.image || item.productVariation.image} width={100} height={100} style={{ width: "100", height: "auto" }} />
                                                            <Box sx={{ flexDirection: "column", ml: 2 }}>
                                                                <Typography fontWeight="bold" fontSize={20}>{item.product[`name_ar`]}</Typography>
                                                                <Typography fontWeight="light" fontSize={15}>{item.product.sku}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <Typography fontWeight="bold" >{item.price === 0 ? t("order.no_price") : `${item.price} ${t("egp")}`}</Typography>
                                                                    <Typography >{item.amount} {t("units")}</Typography>
                                                                </Grid>
                                                                <Grid item xs={6} sx={{ ...right_flex_box }}>
                                                                    {!item.price && <Box>
                                                                        <MainInput required id={`price_${item._id}`} name={`price_${item._id}`} inputMode="decimal" label="Price per piece" />
                                                                    </Box>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                                    {!order?.price && <>
                                        <Box sx={{ ...centered_flex_box, marginY: 2 }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MainDateInput
                                                    sx={{ width: "100%" }}
                                                    label="Expiry Date"
                                                    onChange={({ $D, $M, $y }) => setExpiry(`${$y}-${$M + 1}-${$D}`)}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Button sx={{ ...main_button }} type="submit">Send Price List</Button>
                                    </>}
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (<>
                <Box sx={{ mt: 2 }}>
                    <Typography fontSize={30} fontWeight="bold">Order Details</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <Card sx={{ my: 2, padding: 2 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Status: {order?.status}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Button onClick={() => setStatusOpen(true)} size='small' sx={{ ...main_button }}><ChangeCircleOutlined sx={{ mr: 1 }} /> Change Status</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20} fontWeight="bold">User Info</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Name: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>{order?.user?.name || order?.userInfo?.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Email: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}><NavLink className="a2" to={`mailto:${order?.user?.email || order?.userInfo.email}`}>{order?.user?.email || order?.userInfo.email}</NavLink></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Mobile: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}><NavLink className="a2" to={`tel:${order?.user?.mobile || order?.userInfo.mobile}`}>{order?.user?.mobile || order?.userInfo.mobile}</NavLink></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ ...left_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>Is a user?: </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                                <Typography fontSize={isMobile ? 15 : 20}>{order?.user !== undefined ? "Yes" : "No"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8}>
                            <Box component="form">
                                <Card sx={{ my: 2, padding: 2 }}>
                                    {order?.items?.length > 0 && order?.items?.map((item) => {
                                        return (
                                            <Card key={item._id} sx={{ my: 2 }}>
                                                <CardContent>
                                                    <Grid container spacing={2} rowSpacing={2}>
                                                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                            <Image src={item.product.image || item.productVariation.image} width={100} height={100} style={{ width: "100", height: "auto" }} />
                                                            <Box sx={{ flexDirection: "column", ml: 2 }}>
                                                                <Typography fontWeight="bold" fontSize={20}>{item.product[`name_ar`]}</Typography>
                                                                <Typography fontWeight="light" fontSize={15}>{item.product.sku}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <Typography fontWeight="bold" >{item.price === 0 ? t("order.no_price") : `${item.price} ${t("egp")}`}</Typography>
                                                                    <Typography >{item.amount} {t("units")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                                </Card>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{ my: 2, padding: 2 }}>
                                <Typography fontWeight="bold" textAlign="center" fontSize={22}>{t("form.address")}</Typography>
                                <Typography fontSize={18}>{t("form.address_line1")}: {order?.address.add1}</Typography>
                                {order?.address.add2 && <Typography fontSize={18}>{t("form.address_line2")}: {order?.address.add2}</Typography>}
                                <Typography fontSize={18}>{t("form.city")}: {order?.address.city}</Typography>
                                <hr />
                                {order?.address.location && <Box>
                                    <Typography fontSize={18}>{t("form.location")}</Typography>
                                    <Button href={order?.address.location} target="_blank" fullWidth sx={{...main_button, marginY: 1}}><Map sx={{marginInlineEnd: 1}}/>{t("form.maps")}</Button>
                                </Box>}
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    order: state?.records?.order,
    isLoading: state?.records?.isLoading ||  state?.wait?.isLoading
});

const mapDispatchToProps = { getOrder, editOrderStatus, sendPriceList };

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder);