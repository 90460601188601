import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, CART, CART_SUCCESS, CART_FAIL, LOGOUT, LOGOUT_SUCCESS, FAVOURITE, FAVOURITE_SUCCESS, FAVOURITE_FAIL } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const currentLocale = Cookies.get("i18next") || "en";

export const deleteAccount = (data) => (dispatch, useState) => {
    dispatch({ type: LOGOUT });
    const token = useState()?.auth?.token

    delRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.delete)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: LOGOUT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
        });
};

export const getQuotationCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.quotation.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
                store: "quotation"
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const addToQuotationCart = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: CART });
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.quotation.add)
        .then((response) => {
            const { data } = response;
            navigate && navigate("/cart");
            notification.success({ message: data.message, style: { marginTop: "15vh" } });
            dispatch({ type: WAIT_SUCCESS });
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
                store: "quotation"
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            dispatch({ type: WAIT_FAIL });
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const clearQuotationCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.quotation.clear)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
                store: "quotation"
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const removeFromQuotationCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.quotation.remove)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
                store: "quotation"
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const editItemInQuotationCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.quotation.edit)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
                store: "quotation"
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const orderPriceList = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end = token ? endpoints.user.submitQuoteRequest : endpoints.guest.submitQuoteRequest;

    postRequest(data, { locale: currentLocale }, undefined, token, end)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });


        });
};

export const getCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.cart.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const addToCart = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: CART });
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.cart.add)
        .then((response) => {
            const { data } = response;
            navigate && navigate("/cart");
            notification.success({ message: data.message, style: { marginTop: "15vh" } });
            dispatch({ type: WAIT_SUCCESS });
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            dispatch({ type: WAIT_FAIL });
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const clearCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.cart.clear)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const removeFromCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.cart.remove)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const editItemInCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.cart.edit)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const checkout = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.checkout)
        .then((response) => {
            const { data } = response;
            const { redirect } = data;
            dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: { redirect },
            });
            window.location.href = redirect
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const checkTransaction = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.checkTransaction)
        .then((response) => {
            const { data } = response;
            dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const addAddress = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.address)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data.addresses,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getAddresses = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.address)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getOrders = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.orders.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getOrder = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.orders.getOne)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getFavourites = (data) => (dispatch, useState) => {
    dispatch({ type: FAVOURITE });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.favourites)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FAVOURITE_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FAVOURITE_FAIL,
            });
        });
};

export const toggleFavourite = (data) => (dispatch, useState) => {
    dispatch({ type: FAVOURITE });
    const token = useState()?.auth?.token
    console.log(data)
    postRequest(data, {locale: currentLocale }, undefined, token, endpoints.user.favourites)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FAVOURITE_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FAVOURITE_FAIL,
            });
        });
};