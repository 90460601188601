import React, { useRef, useState, useEffect } from 'react';

const Newsreel = ({ items, scrollSpeed, itemSpacing, isLTR }) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [startX, setStartX] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    const handleMouseDown = (e) => {
      setIsDragging(true);
      setStartX(e.clientX - container.offsetLeft);
      container.style.cursor = 'grabbing';
      e.preventDefault();
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const x = e.clientX - container.offsetLeft;
      const scrollOffset = x - startX;
      container.scrollLeft = isLTR ? scrollLeft + scrollOffset : scrollLeft - scrollOffset;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      container.style.cursor = 'grab';
    };

    container.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, scrollLeft, startX, isLTR]);

  useEffect(() => {
    const container = containerRef.current;
    const scroll = () => {
      if (!isDragging) {
        setScrollLeft(container.scrollLeft);
      }
    };

    container.addEventListener('scroll', scroll);

    return () => {
      container.removeEventListener('scroll', scroll);
    };
  }, [isDragging]);

  useEffect(() => {
    const scrollContainer = containerRef.current;

    const scrollInterval = setInterval(() => {
      if (!isDragging) {
        scrollContainer.scrollLeft = isLTR ? scrollContainer.scrollLeft + 1 : scrollContainer.scrollLeft - 1;
      }
    }, scrollSpeed);

    return () => {
      clearInterval(scrollInterval);
    };
  }, [isDragging, scrollSpeed, isLTR]);

  return (
    <div className="newsreel-container" ref={containerRef}>
      <div className="newsreel-content" style={{ padding: `0 ${itemSpacing}px` }}>
        {items.map((item, index) => (
          <div key={index} className="newsreel-item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Newsreel;
