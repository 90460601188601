import { CART, CART_SUCCESS, CART_FAIL } from "../actions/types";

const initialState = {
    isLoading: false,
    isError: false,
    cart: undefined
};

export default function store(state = initialState, action) {
    const { type, payload, store } = action;
    console.log(payload)
    switch (type) {
        case CART:
            return {
                ...state,
                isLoading: true,
            };
        case CART_SUCCESS:
            if(store) {
                return {
                    ...state,
                    [store]: payload,
                    isLoading: false,
                    isError: false,
                };
            }
            return {
                ...state,
                cart: {...payload},
                isLoading: false,
                isError: false,
            };
        case CART_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
}