import { Email, ExpandMore, Facebook, Phone, Send, Upload, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, right_flex_box } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../../app/config";
import { uploadFile } from "react-s3";
import { orderPriceList } from "../store/actions/usersActions";

function QuotationProdCard({ product, navigate, user, orderPriceList, isLoading }) {
    window.Buffer = window.Buffer || require("buffer").Buffer;

    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false)
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        var details = {
            productId: product._id,
            orderDetails: data.get("details"),
        }

        let fileData = []
        if (file) {
            setUploading(true)
            fileData = await uploadFile(file, { ...config, dirName: "uploads" });
            setUploading(false)
            details.fileUrl = fileData?.location
            details.fileType = file.type.split("/")[1] === "pdf" ? "pdf" : "img"
        }

        if (!user) {
            details.userInfo = {
                name: data.get("name"),
                email: data.get("email"),
                mobile: data.get("mobile")
            }
        }

        orderPriceList(details)
        handleClose()
    }

    const handleRequest = () => {
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleAddFile = (files) => {
        let file = files[0];
        console.log(file);
        setFile(file);
    };

    const handleDeleteFile = () => {
        setFile(null);
    };

    return (
        <>
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                {isLoading || uploading ?
                    (<Box sx={{ ...centered_flex_box, height: "100vh", width: isMobile ? "80vw":"30vw" }}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>) : (<Box component="form" onSubmit={handleSubmit} sx={{ ...centered_flex_box, padding: 2, flexDirection: "column", width: isMobile ? "80vw":"30vw" }}>
                        <Typography fontSize={30} fontWeight="bold">{product[`name_${currentLocale}`]}</Typography>
                        {!user && <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{t("form.userInfo")}*</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MainInput
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label={t("form.name")}
                                        name="name"
                                        autoFocus
                                    />
                                    <MuiPhoneNumber
                                        sx={{
                                            "& label": {
                                                color: "var(--secColor) !important",
                                            },
                                            "& label.Mui-focused": {
                                                color: "var(--secColor) !important",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "1px solid var(--secColor)",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "var(--terColor)",
                                                },

                                                borderRadius: 0.5,
                                            },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="mobile"
                                        label={t("form.mobile")}
                                        name="mobile"
                                        variant='outlined'
                                        defaultCountry={'eg'}
                                        preferredCountries={['eg']}
                                        countryCodeEditable={false}
                                        inputProps={{
                                            minLength: 13
                                        }}
                                        disableAreaCodes
                                    />

                                    <MainInput
                                        margin="normal"
                                        fullWidth
                                        id="email"
                                        label={t("form.email")}
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Typography>{t("or")}</Typography>
                            <Button sx={main_button} onClick={() => navigate("/login")} >{t("menu.signup")}</Button>
                        </>}
                        <ReactFileReader
                            multipleFiles={false}
                            handleFiles={handleAddFile}
                            fileTypes={[".jpeg", ".jpg", ".png", ".svg", ".pdf"]}
                        >
                            <Button fullWidth sx={{ my: 1, ...main_button }}>
                                <Upload /> {t("form.uploadFile")}
                            </Button>
                        </ReactFileReader>
                        {file && (
                            <Chip
                                label={file.name}
                                onDelete={handleDeleteFile}
                                sx={{ color: "var(--appBg)", bgcolor: "var(--secColor)" }}
                            />
                        )}
                        <MainInput
                            margin="normal"
                            multiline
                            minRows={5}
                            fullWidth
                            id="details"
                            label={t("form.orderDetails")}
                            name="details"
                            autoFocus
                        />
                        <Button fullWidth type="submit" sx={main_button}>{t("products.request")}</Button>
                    </Box>)}
            </Dialog>
            <Card sx={{ borderRadius: 5, padding: 2, mx: 1, my: 1, minWidth: isMobile ? "100%" : "20%" }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image={product.image}
                    title={product[`name_${currentLocale}`]}
                />
                <CardContent>
                    <Typography fontSize={30} fontWeight="bold">{product[`name_${currentLocale}`]}</Typography>
                    <Typography fontSize={12} variant="caption">{product[`description_${currentLocale}`]}</Typography>
                </CardContent>
                <CardActionArea sx={right_flex_box}>
                    <Button size="small" sx={main_button} onClick={handleRequest}><Send sx={{ mx: 1 }} />{t("products.request")}</Button>
                </CardActionArea>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.wait?.isLoading
});

const mapDispatchToProps = { orderPriceList };

export default connect(mapStateToProps, mapDispatchToProps)(QuotationProdCard);