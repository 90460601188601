import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { newsletter } from '../../app/store/actions/commonActions';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import { StoreHome } from '../Store';

export const Home = ({ newsletter }) => {

    const navigate = useNavigate()
    const eventId = useParams().id
    const { t, i18n } = useTranslation();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            email: data.get('email'),
            name: data.get('name')
        }
        newsletter(details)
    }
    return (
        <Container sx={{minWidth: isMobile ? "100% !important" : "90% !important"}}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ minWidth: "100vw", minHeight: isMobile ? "80vh" : "100vh", mt: -2, backgroundImage: "url(https://integraecommerce.s3.eu-west-3.amazonaws.com/logos/background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <Box sx={{ ...centered_flex_box, paddingTop: 10, flexDirection: "column" }}>
                        <Image height={100} width={227} preview={false} src={Logo} />
                        <Typography fontWeight="bold" fontSize={isMobile ? 40 : 60}>{t("welcome_text")}</Typography>
                        <Typography fontSize={isMobile ? 20 : 30}>{t("welcome_subtext")}</Typography>
                    </Box>
                </Box>
            </Box>
            <StoreHome />
            <hr />
            <Box sx={{ ...centered_flex_box, my: 5, flexDirection: "column" }}>
                <Typography textAlign="center" fontSize={isMobile ? 20 : 40}>{t("newsletter_message")}</Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} sx={{ ...centered_flex_box, flexDirection: "column", paddingY: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 8 : 6}>
                        <MainInput label={t("form.email")} fullWidth required id="email" name="email" />
                    </Grid>
                    <Grid item xs={isMobile ? 4 : 4}>
                        <MainInput label={t("form.name")} fullWidth id="name" name="name" />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 2} sx={centered_flex_box}>
                        <Button type='submit' fullWidth sx={main_button}>{t("form.register")}</Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = { newsletter };

export default connect(mapStateToProps, mapDispatchToProps)(Home);