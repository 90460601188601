import * as React from 'react';
import { Typography, Table, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Pagination, ToggleButtonGroup, ToggleButton, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainDateInput, MainInput, centered_flex_box, main_button, right_flex_box } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import Newsreel from '../../app/components/Newsreel';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getOrders } from '../../app/store/actions/superActions';
import { useState } from 'react';
import OrderCard from '../../app/components/OrderCard';
import { DashboardCustomize, ExpandMore, Filter, Filter1, FilterAlt, Inbox, TableChart } from '@mui/icons-material';
import moment from 'moment/moment';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'moment/locale/ar'

export const SuperOrders = ({ getOrders, orders, isLoading }) => {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";
    const [filtersOpen, setFiltersOpen] = useState(false);

    const initialOrderFilters = {
        page: 1,
        limit: 100,
        status: undefined,
        email: undefined,
        orderNo: undefined,
        date: undefined,
        type: "order"
    }
    const [orderFilters, setOrderFilters] = useState(initialOrderFilters)
    const { page, limit, status, email, type, orderNo, date } = orderFilters

    const handlePageChange = (event) => {
        setOrderFilters({ ...orderFilters, page: parseInt(event.target.innerText) })
    }

    const [displayVariant, setDisplayVariant] = useState("table")
    const handleDisplayChange = (event) => {
        setDisplayVariant(event.currentTarget.value)
    }

    useEffect(() => {
        getOrders(orderFilters)
    }, [orderFilters])

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10, paddingTop: isMobile ? 0 : 10 }}>
            <Box mb={2}>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography sx={{ fontWeight: "bold", fontSize: isMobile ? 20 : 25 }}>{t(`order.${type}`)}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{...right_flex_box}}>
                        <IconButton onClick={() => setFiltersOpen(!filtersOpen)}><FilterAlt /></IconButton>
                    </Grid>
                </Grid>

                {filtersOpen && <Card sx={{ padding: 2, margin: 1 }}>
                    <MainInput
                        margin="normal"
                        fullWidth
                        id="orderNo"
                        label={t("order.number")}
                        name="orderNo"
                        autoFocus
                        value={orderNo}
                        onChange={({ target }) => setOrderFilters({ ...orderFilters, orderNo: target.value })}
                    />

                    <MainInput
                        margin="normal"
                        fullWidth
                        id="type"
                        label={t("order.type")}
                        name="type"
                        autoFocus
                        select
                        value={type}
                        onChange={({ target }) => setOrderFilters({ ...orderFilters, type: target.value })}
                    >
                        <MenuItem value={"order"}>
                            <Typography>{t("order.order")}</Typography>
                        </MenuItem>
                        <MenuItem value={"quotation"}>
                            <Typography>{t("order.quotation")}</Typography>
                        </MenuItem>
                    </MainInput>

                    <Box sx={{ ...centered_flex_box }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MainDateInput
                                sx={{ width: "100%" }}
                                label={t("order.date")}
                                onChange={({ $D, $M, $y }) => setOrderFilters({ ...orderFilters, date: `${$y}-${$M + 1}-${$D}` })}
                            />
                        </LocalizationProvider>
                    </Box>

                    <br />

                    <Box sx={{ ...centered_flex_box, marginY: 2 }}>
                        <Button sx={{ ...main_button }} onClick={() => setOrderFilters(initialOrderFilters)}>{t("clear_filters")}</Button>
                    </Box>
                </Card>}

                <ToggleButtonGroup
                    value={displayVariant}
                    exclusive
                    onChange={handleDisplayChange}
                    aria-label="text alignment"
                >
                    <ToggleButton value="table">
                        <TableChart value="table" />
                    </ToggleButton>
                    <ToggleButton value="cards">
                        <DashboardCustomize value="cards" />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box>
                {!isLoading ? (<>
                    {displayVariant === "table" ? (
                        <TableContainer>
                            <Table stickyHeader >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">{t("order.user")}</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">{t("order.value")}</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">{t("order.date")}</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">{t("order.status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders?.docs?.map((order) => (
                                        <TableRow
                                            key={order._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                                            onClick={() => (navigate(`/super/orders/${order._id}`))}
                                        >
                                            <TableCell align="center">{order.user?.name || order.userInfo?.name}</TableCell>
                                            <TableCell align="center">{order.price ? `${order.price.toFixed(2)} ${t("egp")}` : t("order.no_price")}</TableCell>
                                            <TableCell align="center">{moment(order.createdAt).locale(currentLocale).format("lll")}</TableCell>
                                            <TableCell align="center">{t(`status.${order.status}`)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={isMobile ? 1 : 3}>
                            {orders?.docs?.map((order) => {
                                return (
                                    <Grid item xs={isMobile ? 12 : 3} sx={centered_flex_box}>
                                        <OrderCard navigate={navigate} order={order} />
                                    </Grid>
                                )
                            })}
                        </Grid>)
                    }
                    {orders?.docs?.length === 0 && (
                        <Box>
                            <Inbox fontSize="large" />
                            <Typography fontSize={40}>{t("no_results")}</Typography>
                        </Box>
                    )}
                </>
                ) : (
                    <Box sx={centered_flex_box}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                    <Pagination count={orders?.results?.pages || 1} page={page} onChange={handlePageChange} />
                </Box>
            </Box>
        </Box >
    );
}

const mapStateToProps = (state) => ({
    orders: state?.records?.orders,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(SuperOrders);