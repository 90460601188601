import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, CardContent, Skeleton, Grid, Dialog, AccordionDetails, AccordionSummary, Accordion, CardHeader } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, reject_button, right_flex_box, sec_button } from '../../app/components/Styles';
import DeleteIcon from '@mui/icons-material/Delete';
import InboxIcon from '@mui/icons-material/Inbox';
import PaymentIcon from "@mui/icons-material/Payment";
import { getAddresses, addAddress, deleteAccount } from '../../app/store/actions/usersActions';
import { Image } from 'antd';
import { isMobile } from "react-device-detect"
import ActionDialog from '../../app/components/ActionDialog';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ExpandMore, Send } from '@mui/icons-material';
import moment from 'moment';

export const Profile = ({ isLoading, getAddresses, user, deleteAccount }) => {

    const navigate = useNavigate()

    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";

    React.useEffect(() => {
        getAddresses({})
    }, [])

    const [dialogOpen, setDialogOpen] = useState(false)
    const [confirmation, setConfirmation] = useState(undefined)

    const handleOpen = (cart) => {
        setDialogOpen({ state: true, cart })
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleDeleteAccount = () => {
        deleteAccount({})
    }

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <>
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                {!isLoading ?
                    <Box sx={{ padding: 2 }}>
                    <Typography variant="h6">{t("account.delete")}</Typography>
                    <Typography variant="p">{t("account.delete_phrase", {phrase: user?.name.toLowerCase().replace(" ", "")})}</Typography>
                    <MainInput fullWidth value={confirmation} onChange={({target}) => setConfirmation(target.value)}/>
                    <Button onClick={handleDeleteAccount} disabled={confirmation !== user?.name.toLowerCase().replace(" ", "")} sx={{...reject_button, marginY: 1}}>{t("account.confirm")}</Button>
                    </Box> : <Box sx={{ ...centered_flex_box, height: "100vh", width: isMobile ? "90vw" : "60vw" }}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>}
            </Dialog>
            <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
                <Card sx={{ padding: 2, marginY: 2 }}>
                    <Box>
                        <Typography variant="h6">{user?.name}</Typography>
                        <Typography variant="p">{t("account.joined")} {moment(user?.createdAt).format("ll")}</Typography>
                    </Box>
                </Card>
                <Card sx={{ padding: 2, marginY: 2 }}>
                    <Box sx={{ ...centered_flex_box }}>
                        <Button onClick={handleOpen} sx={{...reject_button}}>{t("account.delete")}</Button>
                    </Box>
                </Card>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.wait?.isLoading || state?.records?.isLoading,
});

const mapDispatchToProps = { getAddresses, addAddress, deleteAccount };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);