import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Pagination } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import Logo from "../../app/components/integra-logo.png"
import { Image } from 'antd';
import Newsreel from '../../app/components/Newsreel';
import Cookies from 'js-cookie';
import ProductCard from '../../app/components/ProductCard';
import { Inbox, MoreHoriz } from '@mui/icons-material';
import { getProducts } from '../../app/store/actions/commonActions';
import { useEffect } from 'react';
import { useState } from 'react';

export const SearchResults = ({ products, isLoading, getProducts }) => {

    const navigate = useNavigate()
    const term = useParams().search
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";

    const [filters, setFilters] = useState({
        page: 1,
        limit: 100,
        search: term
    })

    const { page, limit, search } = filters

    const handlePageChange = (event, value) => {
        setFilters({ ...filters, page: value })
    }

    useEffect(() => {
        getProducts({ ...filters, locale: currentLocale })
    }, [filters, search])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }
    
    return (
        <Container>
            <Box>
                <Typography sx={{ fontWeight: "bold", fontSize: isMobile ? 20 : 25 }}>{t("search_for", { term })}</Typography>
            </Box>
            <BrowserView>
                <Box sx={{ flexDirection: "column" }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("products.products")}</Typography>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }}>
                        {products?.docs?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3}>
                                <ProductCard product={product} navigate={navigate} />
                            </Grid>
                        })}
                        {products?.docs?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </BrowserView>
            <MobileView>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Typography fontWeight="bold" fontSize={isMobile ? 20 : 30}>{t("products.products")}</Typography>
                    <Grid container spacing={1}>
                        {products?.docs?.length > 0 &&
                            <>
                                {products?.docs?.map((product) => {
                                    return (
                                        <Grid item xs={6}>
                                            <ProductCard product={product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {products?.docs?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </MobileView>
            <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                <Pagination count={products?.pages || 1} page={page} onChange={handlePageChange} />
            </Box>

        </Container>
    );
}

const mapStateToProps = (state) => ({
    products: state?.records?.products,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);