import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const currentLocale = Cookies.get("i18next") || "en";

export const newsletter = (data, navigate) => (dispatch) => {
    dispatch({ type: WAIT });

    postRequest(data, { locale: currentLocale }, undefined, undefined, endpoints.common.newsletter)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            navigate && navigate(-1);
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getProducts = (data, store) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest({ ...data, locale: currentLocale }, undefined, undefined, endpoints.common.products)
        .then((response) => {
            const { data } = response;
            console.log(data)
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getProduct = (data, store) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest({ ...data, locale: currentLocale }, undefined, undefined, endpoints.common.product)
        .then((response) => {
            const { data } = response;
            console.log(data)
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getCategories = (data, store) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest({ ...data, locale: currentLocale }, undefined, undefined, endpoints.common.categories)
        .then((response) => {
            const { data } = response;
            console.log(data)
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getCategory = (data, store) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest({ ...data, locale: currentLocale }, undefined, undefined, endpoints.common.category)
        .then((response) => {
            const { data } = response;
            console.log(data)
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getSubcategory = (data, store) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest({ ...data, locale: currentLocale }, undefined, undefined, endpoints.common.subcategory)
        .then((response) => {
            const { data } = response;
            console.log(data)
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getBrands = (data, store) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest({ ...data, locale: currentLocale }, undefined, undefined, endpoints.common.brands)
        .then((response) => {
            const { data } = response;
            console.log(data)
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getBrand = (data, store) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest({ ...data, locale: currentLocale }, undefined, undefined, endpoints.common.brand)
        .then((response) => {
            const { data } = response;
            console.log(data)
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const wakeServer = () => () => {
    getRequest(undefined, undefined, undefined, endpoints.common.wake)
        .then((response) => {

        })
        .catch((err) => {
            notification.error({ message: currentLocale === "ar" ? "رجاء تحديث الصفحة" : "Server is down, please refresh", style: { marginTop: "15vh" } })
            console.log(err);
        });
};