import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Stack, Pagination, CardMedia, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { isMobile } from 'react-device-detect';
import { centered_flex_box } from '../../app/components/Styles';

export const IFramePayment = ({ url }) => {
    const iframeRef = React.useRef(null);

    // Function to check the iframe URL
    const checkIframeUrl = () => {
      if (iframeRef.current) {
        console.log('Current iframe:', iframeRef.current);
        try{
            const iframeUrl = iframeRef.current.contentWindow?.location?.href;
            console.log('Current iframe URL:', iframeUrl);
        } catch (err) {
            console.error(err)
        }
      }
    };
  
    // Use useEffect to run the checkIframeUrl function on mount and at intervals
    React.useEffect(() => {
        checkIframeUrl()
    }, [iframeRef.current]);

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10, ...centered_flex_box, height: "120vh" }}>
            <iframe ref={iframeRef} width="100%" height= "100%" src={url} title="Paymob Payment" />
        </Box>
    );
}

const mapStateToProps = (state) => ({
    url: state?.records?.redirect
});

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(IFramePayment);