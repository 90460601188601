import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box } from '../../app/components/Styles';
import { t } from 'i18next';
import { Image } from 'antd';
import { isMobile } from 'react-device-detect';
import Logo from "../../app/components/integra-logo.png"
import { useTranslation } from 'react-i18next';

export const ComingSoon = ({ }) => {

    const { t, i18n } = useTranslation();

    return (
        <Container>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ ...centered_flex_box, paddingTop: 10, flexDirection: "column" }}>
                    <Image height={100} width={227} preview={false} src={Logo} />
                </Box>
                <ConstructionIcon sx={{ fontSize: "300px" }} />
                <Typography fontSize={40}>{t("under_construction")}</Typography>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);