import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, CardContent, Skeleton, Grid, Dialog, AccordionDetails, AccordionSummary, Accordion } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../../app/components/Styles';
import DeleteIcon from '@mui/icons-material/Delete';
import InboxIcon from '@mui/icons-material/Inbox';
import PaymentIcon from "@mui/icons-material/Payment";
import { getCart, clearCart, removeFromCart, getAddresses, addAddress, checkout, getQuotationCart, clearQuotationCart, orderPriceList } from '../../app/store/actions/usersActions';
import { Image } from 'antd';
import { isMobile } from "react-device-detect"
import ActionDialog from '../../app/components/ActionDialog';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ExpandMore, Send } from '@mui/icons-material';
import provinces from "../../constants/locations.json"
import moment from 'moment';
import 'moment/locale/ar'

export const Cart = ({ getCart, clearCart, removeFromCart, cart, isLoading, prices, getAddresses, recordsLoading, addresses, addAddress, checkout, waitLoading, getQuotationCart, quotationCart, clearQuotationCart, orderPriceList }) => {

    const navigate = useNavigate()
    const [clearDialog, setClearDialog] = React.useState(false)
    const [clearQuotationDialog, setClearQuotationDialog] = React.useState(false)
    const [address, setAddress] = useState(undefined)
    const [paymentMethod, setPaymentMethod] = useState("Online")
    const [city, setCity] = useState("Cairo")
    const [deliveryFees, setDeliveryFees] = useState(0)
    const [convenienceFees, setConvenienceFees] = useState(0);

    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";

    React.useEffect(() => {
        getCart({})
        getQuotationCart({})
    }, [])

    const handleAddAddress = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const address = {
            name: data.get('name'),
            add1: data.get('add1'),
            add2: data.get('add2'),
            city: data.get('city'),
            province: data.get('province'),
            location: data.get('location'),
        }
        addAddress(address)
        getAddresses({})
    }

    const handleClearCart = () => {
        clearCart({})
        setClearDialog(false)
    }

    const handleCheckout = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const orderInfo = {
            address: address,
            paymentMethod,
            deliveryFees,
            convenienceFees,
            mobile: data.get('mobile')
        }
        checkout(orderInfo, navigate)
        handleClose()
        getCart({})
        getQuotationCart({})
    }

    const handleClearQuotationCart = () => {
        clearQuotationCart({})
        setClearQuotationDialog(false)
    }

    const handleSendRequest = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const orderInfo = {
            address: address,
            productId: "6522b023e414d5b11b08fbbe",
            itemizedOrderId: quotationCart?._id
        }
        orderPriceList(orderInfo)
        handleClose()
        getCart({})
        getQuotationCart({})
    }

    const [dialogOpen, setDialogOpen] = useState({ state: false, cart: "Cart" })

    const handleOpen = (cart) => {
        setDialogOpen({ state: true, cart })
        getAddresses({})
    }

    const handleClose = () => {
        setDialogOpen({ state: false, cart: "Cart" })
    }

    React.useEffect(() => {
        if (addresses && addresses.length > 0) {
            setAddress(addresses[0]._id)
        }
    }, [addresses])

    React.useEffect(() => {
        switch(paymentMethod){
            case "Mobile":
            case "Online": setConvenienceFees(((deliveryFees + prices?.actualPrice) * 0.0275 + 5) * 1.14); break;
            case "ValU": 
            case "Forsa": setConvenienceFees(((deliveryFees + prices?.actualPrice) * 0.04 + 5) * 1.14); break;
            case "Sympl": setConvenienceFees(((deliveryFees + prices?.actualPrice) * 0.06 + 5) * 1.14); break;
            default: break;
        }
    }, [paymentMethod, deliveryFees, prices])

    React.useEffect(() => {
        let fee = 0
        if (cart?.items?.filter(({ product }) => product.size === "Bulky").length > 0) {
            fee += 175
        }
        if (cart?.items?.filter(({ product }) => product.size !== "Bulky").length > 0) {
            fee += 75
        }
        setDeliveryFees(fee)
    }, [cart])

    if (waitLoading || recordsLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <>
            <Dialog
                open={dialogOpen.state}
                onClose={handleClose}
            >
                {!recordsLoading && !waitLoading ?
                    <Box sx={{ padding: 2 }}>
                        <Typography fontSize="large">{t("order.checkout")}</Typography>
                        {(address === "other" || !address) && <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{t("order.other_address")}*</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box component="form" onSubmit={handleAddAddress}>
                                        <MainInput
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="name"
                                            label={t("form.name")}
                                            name="name"
                                            autoFocus
                                        />

                                        <MainInput
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="add1"
                                            label={t("form.address_line1")}
                                            name="add1"
                                            autoFocus
                                        />

                                        <MainInput
                                            margin="normal"
                                            fullWidth
                                            id="add2"
                                            label={t("form.address_line2")}
                                            name="add2"
                                            autoFocus
                                        />

                                        <MainInput
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="city"
                                            label={t("form.city")}
                                            name="city"
                                            autoFocus
                                            select
                                            defaultValue={city}
                                            onChange={({ target }) => setCity(target.value)}
                                        >
                                            <MenuItem key={1} value={"Cairo"}>
                                                {t("locations.cairo")}
                                            </MenuItem>
                                            <MenuItem key={2} value={"Giza"}>
                                                {t("locations.giza")}
                                            </MenuItem>
                                        </MainInput>

                                        <MainInput
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="province"
                                            label={t("form.province")}
                                            name="province"
                                            autoFocus
                                            select
                                        >
                                            {provinces[city]
                                                .map((province) => ({
                                                    originalName: province,
                                                    translatedName: t(`locations.${province}`)
                                                }))
                                                .sort((a, b) => a.translatedName.localeCompare(b.translatedName))
                                                .map((sortedProvince) => (
                                                    <MenuItem key={2} value={sortedProvince.translatedName}>
                                                        {sortedProvince.translatedName}
                                                    </MenuItem>
                                                ))}
                                        </MainInput>

                                        <MainInput
                                            margin="normal"
                                            fullWidth
                                            id="location"
                                            label={t("form.location")}
                                            name="location"
                                            autoFocus
                                        />
                                        <Button type='submit' sx={{ ...main_button }} fullWidth>{t("form.add_address")}</Button>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>

                            <Typography>{t("or")}</Typography>
                        </>}

                        <Box component="form" onSubmit={(event) => { dialogOpen.cart === "C" ? handleCheckout(event) : handleSendRequest(event) }} >
                            {addresses && addresses.length > 0 && <>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="address"
                                    label={t("form.address")}
                                    name="address"
                                    autoFocus
                                    select
                                    defaultValue={address}
                                    onChange={({ target }) => setAddress(target.value)}
                                >
                                    <MenuItem value={"other"}>
                                        <Typography>{t("form.other")}</Typography>
                                    </MenuItem>
                                    {addresses.map((address) => {
                                        return <MenuItem key={address._id} value={address._id}>
                                            <Box>
                                                <Typography textAlign="center">{address.name}</Typography>
                                                <Typography textAlign="center">{address.add1}, {address.city}, {address.country}</Typography>

                                            </Box>
                                        </MenuItem>
                                    })}
                                </MainInput>
                            </>}

                            <hr />

                            {dialogOpen.cart === "C" ? <>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="paymentMethod"
                                    label={t("form.payment")}
                                    name="paymentMethod"
                                    autoFocus
                                    select
                                    value={paymentMethod}
                                    onChange={({ target }) => setPaymentMethod(target.value)}
                                >
                                    {/* <MenuItem value={"COD"}>
                                        <Typography>{t("form.cod")}</Typography>
                                    </MenuItem> */}
                                    <MenuItem value={"Online"}>
                                        <Typography>{t("form.online")}</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Mobile"}>
                                        <Typography>{t("form.wallet")}</Typography>
                                    </MenuItem>
                                    <MenuItem disabled = {deliveryFees + prices?.actualPrice + convenienceFees < 500} value={"ValU"}>
                                        <Typography>{t("form.valU")}</Typography>
                                    </MenuItem>
                                </MainInput>
                                {paymentMethod === "Mobile" && <MuiPhoneNumber
                                    sx={{
                                        "& label": {
                                            color: "var(--secColor) !important",
                                        },
                                        "& label.Mui-focused": {
                                            color: "var(--secColor) !important",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "1px solid var(--secColor)",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "var(--terColor)",
                                            },

                                            borderRadius: 0.5,
                                        },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    required
                                    id="mobile"
                                    label={t("login.mobile")}
                                    name="mobile"
                                    variant='outlined'
                                    defaultCountry={'eg'}
                                    preferredCountries={['eg']}
                                    countryCodeEditable={false}
                                    inputProps={{
                                        minLength: 13
                                    }}
                                    disableAreaCodes
                                />
                                }
                                <hr />
                                {paymentMethod !== "COD" ? (<>
                                    <Typography fontSize={isMobile ? 18 : 25}>{t("order.items_total", { num: prices?.actualPrice?.toFixed(2) })}</Typography>
                                    <Typography fontSize={isMobile ? 18 : 25}>{t("order.delivery", { num: deliveryFees?.toFixed(2) })}</Typography>
                                    <Typography fontSize={isMobile ? 18 : 25}>{t("order.fees", { num: convenienceFees.toFixed(2) })}</Typography>
                                    <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>{t("order.total", { num: (deliveryFees + prices?.actualPrice + convenienceFees).toFixed(2) })}</Typography>
                                </>) : (<>
                                    <Typography fontSize={isMobile ? 18 : 25}>{t("order.fees", { num: 50 })}</Typography>
                                    <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>{t("order.total", { num: (deliveryFees + prices?.actualPrice).toFixed(2) })}</Typography>
                                </>)}
                                <hr />
                                <Button type='submit' sx={{ ...main_button }} fullWidth>{t("order.proceed")}</Button>
                            </> : <>
                                <Button type='submit' sx={{ ...main_button }} fullWidth>{t("products.request")}</Button>
                            </>}
                        </Box>
                    </Box> : <Box sx={{ ...centered_flex_box, height: "100vh", width: isMobile ? "90vw" : "60vw" }}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>}
            </Dialog>
            <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
                <ActionDialog open={clearDialog} handleClose={() => setClearDialog(false)} handleAccept={handleClearCart} title={t("clear_cart")} />
                <Card sx={{ background: "white !important", color: "var(--secColor) !important" }}>
                    <CardContent>
                        <Grid container sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                                <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>{t("menu.my_cart")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography onClick={() => setClearDialog(true)} fontWeight="bold" textAlign="end" sx={{ color: "red", textDecorationLine: "underline", cursor: "pointer" }} fontSize={isMobile ? 18 : 25}>{t("remove_all")}</Typography>
                            </Grid>
                        </Grid>
                        {isLoading ? <>
                            <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                            <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                            <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                        </> : <>
                            {cart?.items?.length > 0 && cart?.items?.map((item) => {
                                return (
                                    <Card key={item._id} sx={{ my: 2 }}>
                                        <CardContent>
                                            <Grid container spacing={2} rowSpacing={2}>
                                                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row", cursor: "pointer" }} onClick={() => navigate(`/product/${item.product._id}`)} >
                                                    <Image src={item.product.image || item.productVariation.image} width={100} height={100} style={{ width: "100", height: "auto" }} />
                                                    <Box sx={{ flexDirection: "column", marginInlineStart: 2 }}>
                                                        <Typography fontWeight="bold" fontSize={20}>{item.product[`name_${currentLocale}`]}</Typography>
                                                        <Typography fontWeight="light" fontSize={15}>{item.product.sku}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography fontWeight="bold" >{item.price} {t("egp")}</Typography>
                                                            <Typography >{item.amount} {t("units")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ ...right_flex_box }}>
                                                            <Button sx={{ ...sec_button }} size='small' onClick={() => removeFromCart({ cartItemId: item._id })}>{t("remove")}</Button>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                {item.expires && moment().isBefore(item.expires) && <Grid item xs={12} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                    <Typography fontWeight="bold" >{t("order.expires", { date: moment(item.expires).locale(currentLocale).fromNow() })}</Typography>
                                                </Grid>}
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                            {cart?.items?.length === 0 && <>
                                <Box sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                    <InboxIcon fontSize="large" />
                                    <Typography fontSize={40}>{t("cart_empty")}</Typography>
                                </Box>
                            </>}
                        </>}
                        <Grid container spacing={1} sx={{ mt: 2, ...left_flex_box }}>
                            <Grid item xs={12}>
                                <hr />
                                <Typography fontSize={isMobile ? 18 : 25}>{t("order.items_total", { num: prices?.actualPrice?.toFixed(2) })}</Typography>
                                <Typography fontSize={isMobile ? 18 : 25}>{t("order.delivery", { num: deliveryFees?.toFixed(2) })}</Typography>
                                <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>{t("order.total", { num: (deliveryFees + prices?.actualPrice).toFixed(2) })}</Typography>
                                <hr />
                            </Grid>
                            <Grid item xs={12} sx={{ ...centered_flex_box }}>
                                <Button sx={{ ...main_button }} disabled={cart?.items?.length === 0} onClick={() => { handleOpen("C") }}><PaymentIcon sx={{ marginInlineEnd: 1 }} />{t("buy_now")}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            {quotationCart?.items?.length > 0 && <Box sx={{ paddingX: isMobile ? 2 : 10, paddingY: 2 }}>
                <ActionDialog open={clearQuotationDialog} handleClose={() => setClearQuotationDialog(false)} handleAccept={handleClearQuotationCart} title={t("clear_cart")} />
                <Card sx={{ background: "white !important", color: "var(--secColor) !important" }}>
                    <CardContent>
                        <Grid container sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                                <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>{t("menu.my_quotation_cart")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography onClick={() => setClearQuotationDialog(true)} fontWeight="bold" textAlign="end" sx={{ color: "red", textDecorationLine: "underline", cursor: "pointer" }} fontSize={isMobile ? 18 : 25}>{t("remove_all")}</Typography>
                            </Grid>
                        </Grid>
                        {isLoading ? <>
                            <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                            <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                            <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                        </> : <>
                            {quotationCart?.items?.length > 0 && quotationCart?.items?.map((item) => {
                                return (
                                    <Card key={item._id} sx={{ my: 2 }}>
                                        <CardContent>
                                            <Grid container spacing={2} rowSpacing={2}>
                                                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row", cursor: "pointer" }} onClick={() => navigate(`/product/${item.product._id}`)} >
                                                    <Image src={item.product.image || item.productVariation.image} width={100} height={100} style={{ width: "100", height: "auto" }} />
                                                    <Box sx={{ flexDirection: "column", marginInlineStart: 2 }}>
                                                        <Typography fontWeight="bold" fontSize={20}>{item.product[`name_${currentLocale}`]}</Typography>
                                                        <Typography fontWeight="light" fontSize={15}>{item.product.sku}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography fontWeight="bold" >{item.price === 0 ? t("order.no_price") : `${item.price} ${t("egp")}`}</Typography>
                                                            <Typography >{item.amount} {t("units")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ ...right_flex_box }}>
                                                            <Button sx={{ ...sec_button }} size='small' onClick={() => removeFromCart({ cartItemId: item._id })}>{t("remove")}</Button>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                            {quotationCart?.items?.length === 0 && <>
                                <Box sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                    <InboxIcon fontSize="large" />
                                    <Typography fontSize={40}>{t("cart_empty")}</Typography>
                                </Box>
                            </>}
                        </>}
                        <Grid container sx={{ mt: 2, ...centered_flex_box }}>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6} sx={{ ...right_flex_box }}>
                                <Button sx={{ ...main_button }} disabled={quotationCart?.items?.length === 0} onClick={() => { handleOpen("Q") }}><Send sx={{ marginInlineEnd: 1 }} />{t("products.request")}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>}
            <Button onClick={() => navigate("/store")} fullWidth sx={{ color: "var(--secColor)" }}>
                {t("continue_shopping")}
            </Button>
        </>
    );
}

const mapStateToProps = (state) => ({
    cart: state?.cart?.cart?.cart,
    quotationCart: state?.cart?.quotation?.quotationCart,
    prices: state?.cart?.cart,
    addresses: state?.records?.addresses,
    isLoading: state?.cart?.isLoading,
    recordsLoading: state?.records?.isLoading,
    waitLoading: state?.wait?.isLoading
});

const mapDispatchToProps = { getCart, clearCart, removeFromCart, getAddresses, addAddress, checkout, getQuotationCart, clearQuotationCart, orderPriceList };

export default connect(mapStateToProps, mapDispatchToProps)(Cart);