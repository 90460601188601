import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Dialog, Pagination, Card, CardContent, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getProduct, getProducts } from '../../app/store/actions/commonActions';
import Logo from "../../app/components/integra-logo.png"
import { Carousel, Image, notification } from 'antd';
import { StoreHome } from '../Store';
import { useEffect } from 'react';
import { Add, AddShoppingCart, Inbox, MoreHoriz, Notifications, Remove } from '@mui/icons-material';
import ProductCard from '../../app/components/ProductCard';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { addToCart, addToQuotationCart } from '../../app/store/actions/usersActions';

export const ProductHome = ({ getProduct, getProducts, isLoading, product, products, brandProducts, user, addToCart, addToQuotationCart }) => {

    const navigate = useNavigate()
    const productId = useParams().id
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();
    const [quantity, setQuantity] = useState(0)
    const [price, setPrice] = useState(0)
    const [color, setColor] = useState(undefined)
    const [variant, setVariant] = useState(undefined)


    const increment = () => {
        if (quantity === 99) return
        if (quantity === 0) {
            setQuantity(product.minOrder)
            return
        }
        setQuantity(quantity + product.batchSize)
    }

    const decrement = () => {
        if (quantity === 0) return
        if (quantity === product.minOrder) {
            setQuantity(0)
            return
        }
        setQuantity(quantity - product.batchSize)
    }

    const handleAddToCart = () => {
        if (!user) {
            notification.info({ message: t("must_login"), style: { marginTop: "10vh" } })
            navigate("/login")
        } else {
            const data = {
                productId,
                price: price * quantity,
                amount: quantity,
                productVariation: {
                    ...variant,
                    color
                }
            }
            if (price === 0) {
                addToQuotationCart(data, navigate)
            } else {
                addToCart(data, navigate)
            }
        }
    }

    useEffect(() => {
        getProduct({ productId })
    }, [productId])

    useEffect(() => {
        getProducts({ page: 1, limit: 12, method: "ByPiece", subcategory: product?.subcategory })
        getProducts({ page: 1, limit: 12, method: "ByPiece", brand: product?.brand?._id }, "brandProducts")
        if (product) {
            setPrice(product?.variants.length > 0 ? product?.variants[0].price : product?.price)
            setVariant(product?.variants.length > 0 ? product?.variants[0] : undefined)
            setColor(product?.colors.length > 0 ? product?.colors[0] : undefined)
        }
    }, [product])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container sx={{ maxWidth: isMobile ? "100% !important" : "80% !important" }}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ minWidth: "100vw", minHeight: isMobile ? "20vh" : "40vh", mt: -2, mb: "74px", backgroundImage: "url(https://integraecommerce.s3.eu-west-3.amazonaws.com/logos/background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center', position: "relative" }}>
                    <Avatar className="position-absolute top-100 start-50 translate-middle" alt={product && product[`name_${currentLocale}`]} sx={{ width: 128, height: 128, background: "white", padding: 2 }} ><Image src={product?.image || variant?.image} /></Avatar>
                </Box>
                <Typography fontWeight="bold">{product && product[`name_${currentLocale}`]}</Typography>
                <Typography>{product?.sku}</Typography>

            </Box>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", my: 3 }}>
                <Box sx={{ width: "100%" }}>
                    <Grid container>
                        <Grid item xs={product?.stock === 0 ? 6 : 12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                            {!product?.discount && <Typography textAlign="left" fontWeight="bold" fontSize={18} >{price === 0 ? t("order.no_price") : `${price.toFixed(2)} ${t("egp")}`}</Typography>}
                            {product?.discount && <>
                                <Typography textAlign="left" sx={{ textDecorationLine: "line-through" }} fontWeight="bold" fontSize={16} >{price.toFixed(2)} {t("egp")}</Typography>
                                <Typography textAlign="left" sx={{ color: "green !important" }} fontWeight="bold" fontSize={18} >{(price * (1 - product.discount.value)).toFixed(2)} {t("egp")}</Typography>
                            </>}
                            {product?.stock < 20 && product?.stock > 0 && <Typography textAlign="left" fontWeight="bold" color="error" fontSize={18} >{t("products.low_stock", { num: product?.stock })}</Typography>}
                        </Grid>
                        <Grid item xs={6} sx={{ ...centered_flex_box }}>
                            {product?.stock === 0 && <Typography fontSize={15} >{t("out_of_stock")}</Typography>}
                        </Grid>
                    </Grid>
                </Box>
                {product?.stock !== 0 && <>
                    {product?.variants?.length > 0 && <MainInput
                        select
                        fullWidth
                        required
                        name='variant'
                        id='variant'
                        onChange={({ target }) => { setPrice(target.value.price); setVariant(target.value) }}
                        label={t("product.variant")}
                        value={variant}
                        sx={{ my: 1 }}
                    >
                        {product?.variants?.map((variant) => {
                            return <MenuItem value={variant}>
                                {variant.size_mm && <Typography>Size: {variant.size_mm} {variant.size_inch ? `|| ${variant.size_inch} inch` : ""}</Typography>}
                                {variant.amp && <Typography>Ampere: {variant.amp}</Typography>}
                                {variant.weight && <Typography>Weight: {variant.weight} Kg</Typography>}
                                {variant.volume && <Typography>Volume: {variant.volume} L</Typography>}
                                {variant.ways && <Typography>Ways: {variant.ways}</Typography>}
                                {variant.area && <Typography>Area: {variant.area} mm</Typography>}
                                {variant.length && <Typography>Length: {variant.length} m</Typography>}
                            </MenuItem>;
                        })}
                    </MainInput>}

                    {product?.colors?.length === 0 && product?.category?._id === "6526c7e0bf1fa16676501011" && <MainInput
                        fullWidth
                        required
                        name='color'
                        id='color'
                        onChange={({ target }) => { setColor(target.value) }}
                        label={t("product.color")}
                        value={color}
                        sx={{ my: 1 }}
                    />}

                    {product?.colors?.length > 0 && <MainInput
                        select
                        fullWidth
                        required
                        name='color'
                        id='color'
                        onChange={({ target }) => { setColor(target.value) }}
                        label={t("product.color")}
                        value={color}
                        sx={{ my: 1 }}
                    >
                        {product?.colors?.map((color) => {
                            return <MenuItem sx={{ ...left_flex_box, flexDirection: "row" }} value={color}>
                                <Avatar variant="square" sx={{ ...(color.includes("-") ? { background: `linear-gradient(45deg, ${color.split("-")[0]} 25%, ${color.split("-")[1]} 25%, ${color.split("-")[1]} 50%, ${color.split("-")[0]} 50%, ${color.split("-")[0]} 75%, ${color.split("-")[1]} 75%, ${color.split("-")[1]})`, backgroundSize: "8px 8px" } : { background: color }), color: "transparent", border: "1px solid black", marginInlineEnd: 2 }} /><Typography>{t(`colors.${color}`)}</Typography>
                            </MenuItem>;
                        })}
                    </MainInput>}

                    <Box sx={{ display: "flex", flexDirection: "row", my: 2 }}>
                        <IconButton onClick={decrement}><Remove /></IconButton>
                        <MainInput value={quantity} sx={{ width: 50 }} inputMode="numeric" />
                        <IconButton onClick={increment}><Add /></IconButton>
                    </Box>
                    <Box sx={{ ...(isMobile ? { width: "100%" } : {}) }}>
                        {quantity > 0 && price !== 0 && <Typography mb={2} fontWeight="bold" fontSize={20} >{t("order.total", { num: (quantity * (product.discount ? price * (1 - product.discount.value) : price)).toFixed(2) })}</Typography>}
                        {price !== 0 && <Button disabled={quantity === 0} onClick={handleAddToCart} fullWidth sx={{ ...main_button }}><AddShoppingCart sx={{ marginInlineEnd: 1 }} />{t("add_to_cart")}</Button>}
                        {price === 0 && <Button disabled={quantity === 0 || (product?.colors?.length === 0 && product?.category?._id === "6526c7e0bf1fa16676501011" && !color)} onClick={handleAddToCart} fullWidth sx={{ ...main_button }}><AddShoppingCart sx={{ marginInlineEnd: 1 }} />{t("products.quotation_products")}</Button>}
                    </Box>
                </>}
                {product?.stock === 0 && <Box sx={{ ...(isMobile ? { width: "100%" } : {}), mt: 2 }}>
                    <Button fullWidth sx={{ ...main_button }}><Notifications sx={{ marginInlineEnd: 1 }} />{t("notify_me")}</Button>
                </Box>}

            </Box>
            <Box sx={{ padding: 2 }}>
                {product?.description_en &&
                    <Box>
                        {product.description_en.about && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.about")} :</Typography>
                            <Typography component="pre" fontSize={isMobile ? 13 : 15} >{product[`description_${currentLocale}`]?.about}</Typography>
                        </>}
                        {product.description_en.composition && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.composition")} :</Typography>
                            <Typography fontSize={isMobile ? 13 : 15} >{product[`description_${currentLocale}`]?.composition}</Typography>
                        </>}
                        {product.description_en.features && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.features")} :</Typography>
                            {product[`description_${currentLocale}`]?.features.map((feature) => { return <Typography fontSize={isMobile ? 13 : 15} >• {feature}</Typography> })}
                        </>}
                        {product.description_en.applications && <>
                            <Typography fontWeight="bold" fontSize={isMobile ? 15 : 18} >{t("product.applications")} :</Typography>
                            {product[`description_${currentLocale}`]?.applications.map((application) => { return <Typography fontSize={isMobile ? 13 : 15} >• {application}</Typography> })}
                        </>}
                    </Box>}
                {product?.catalog && <Box sx={{ height: "80vh", p: 2 }}>
                    <iframe title={product[`name_${currentLocale}`]} src={product?.catalog} height={"100%"} width={"100%"}></iframe>
                </Box>}

                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={10} sx={{ justifyContent: "center", alignItems: "start", flexDirection: "column" }}>
                                {product?.weight && <Typography>{t("product.weight")}: {product?.weight}</Typography>}
                                {product?.countryOfOrigin && <Typography>{t("product.countryOfOrigin")}: {product?.countryOfOrigin}</Typography>}
                                {product?.dimensions && <Typography>Dimensions: {product?.dimensions}</Typography>}
                                {product?.color && <Typography>{t("product.color")}: {t(`colors.${product?.color}`)}</Typography>}
                                {product?.subcategory && <Typography>{t("product.category")}: {product?.subcategory[`name_${currentLocale}`]}</Typography>}
                            </Grid>
                            <Grid item xs={2} sx={{ ...centered_flex_box }}>
                                <Image src={product?.brand.logo} height={80} width={80} />
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Box>
            <BrowserView>
                <Box sx={{ flexDirection: "column" }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("products.similar_products")}</Typography>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={6}>
                        {products?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3} sx={{ ...centered_flex_box }}>
                                <ProductCard product={product} navigate={navigate} />
                            </Grid>
                        })}
                        {products?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box sx={{ flexDirection: "column" }}>
                    <Typography fontSize={isMobile ? 20 : 30}>{t("products.more_products", { brand: product?.brand?.name })}</Typography>
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={6}>
                        {brandProducts?.map((product) => {
                            return <Grid item xs={isMobile ? 12 : 3} sx={{ ...centered_flex_box }}>
                                <ProductCard product={product} navigate={navigate} />
                            </Grid>
                        })}
                        {brandProducts?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </BrowserView>
            <MobileView>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Typography fontWeight="bold" fontSize={isMobile ? 20 : 30}>{t("products.similar_products")}</Typography>
                    <Grid container spacing={1}>
                        {products?.length > 0 &&
                            <>
                                {products?.map((product) => {
                                    return (
                                        <Grid item xs={6}>
                                            <ProductCard product={product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {products?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box container sx={{ flexDirection: "column", mt: 2 }}>
                    <Typography fontWeight="bold" fontSize={isMobile ? 20 : 30}>{t("products.more_products", { brand: product?.brand?.name })}</Typography>
                    <Grid container spacing={1}>
                        {brandProducts?.length > 0 &&
                            <>
                                {brandProducts?.map((product) => {
                                    return (
                                        <Grid item xs={6}>
                                            <ProductCard product={product} navigate={navigate} />
                                        </Grid>
                                    )
                                })}
                            </>}
                        {brandProducts?.length === 0 && (
                            <Grid xs={12} item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <Inbox fontSize="large" />
                                <Typography fontSize={40}>{t("no_results")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </MobileView>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    product: state?.records?.product,
    subcategories: state?.records?.subcategories,
    products: state?.records?.products?.docs,
    brandProducts: state?.records?.brandProducts?.products?.docs,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading
});

const mapDispatchToProps = { getProduct, getProducts, addToCart, addToQuotationCart };

export default connect(mapStateToProps, mapDispatchToProps)(ProductHome);