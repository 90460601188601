import * as React from "react";
import {
    Container,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
export const Terms = ({ }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";

    return (
        <Container sx={{ padding: 10 }}>
            {currentLocale === "ar" ? <>
                <h2><strong>الشروط و الأحكام</strong></h2>

                <p>مرحبًا بك في إنتجرا للتجارة الإلكترونية!</p>

                <p>تحدد هذه الشروط و الأحكام القواعد واللوائح لاستخدام موقع الويب التابع لشركة إنتجرا لحلول البناء وهندسة التقنيات، والذي يقع على العنوان: https://www.integraegy.com.</p>

                <p>من خلال الوصول إلى هذا الموقع، نفترض أنك تقبل هذه الشروط و الأحكام. لا تواصل استخدام إنتجرا للتجارة الإلكترونية إذا لم توافق على جميع الشروط و الأحكام المذكورة على هذه الصفحة.</p>

                <p>المصطلحات التالية تنطبق على هذه الشروط و الأحكام، بيان الخصوصية، وإشعار إخلاء المسؤولية وجميع الاتفاقيات: "العميل"، "أنت" و"أنتم" تشير إليك، الشخص الذي يقوم بتسجيل الدخول إلى هذا الموقع والامتثال لأحكام وشروط الشركة. "الشركة"، "أنفسنا"، "نحن"، "نحن" و"نحن" تشير إلى شركتنا. "الحزب"، "الأطراف"، أو "نحن" تشير إلى العميل وأنفسنا. تشير جميع المصطلحات إلى العرض والقبول والنظر في الدفع الضروري للقيام بعملية المساعدة للعميل بأسلوب مناسب لتلبية احتياجات العميل فيما يتعلق بتقديم خدمات الشركة المعلنة، وفقًا للقانون الساري المعمول به في مصر. أي استخدام للمصطلحات المذكورة أعلاه أو كلمات أخرى في الصف، الجمع، التحريف، و/أو هو/هم، تعتبر قابلة للتبديل وبالتالي تشير إلى نفس المعنى.</p>

                <h3><strong>الكوكيز</strong></h3>

                <p>نستخدم ملفات تعريف الارتباط (الكوكيز). من خلال الوصول إلى إنتجرا للتجارة الإلكترونية، فإنك توافق على استخدام ملفات تعريف الارتباط وفقًا لسياسة الخصوصية لشركة إنتجرا لحلول البناء وهندسة التقنيات.</p>

                <p>تستخدم معظم المواقع التفاعلية ملفات تعريف الارتباط للسماح لنا بجلب تفاصيل المستخدم في كل زيارة. تُستخدم ملفات تعريف الارتباط من قبل موقعنا لتمكين وظيفة بعض المناطق وجعلها أكثر سهولة للزوار القادمين إلى موقعنا. قد تستخدم بعض شركاءنا في العروض التابعة والإعلانات أيضًا ملفات تعريف الارتباط.</p>

                <h3><strong>ترخيص</strong></h3>

                <p>ما لم ينص على خلاف ذلك، فإن إنتجرا لحلول البناء وهندسة التقنيات و/أو منتجاتها تمتلك حقوق الملكية الفكرية لجميع المواد الموجودة على إنتجرا للتجارة الإلكترونية. جميع حقوق الملكية الفكرية محفوظة. يمكنك الوصول إلى هذا من إنتجرا للتجارة الإلكترونية لاستخدام شخصي محدد بشروط محددة في هذه الشروط و الأحكام.</p>

                <p>يجب عليك أن لا تقوم بما يلي:</p>
                <ul>
                    <li>إعادة نشر المواد من إنتجرا للتجارة الإلكترونية</li>
                    <li>بيع المواد من إنتجرا للتجارة الإلكترونية</li>
                    <li>استنساخ أو نسخ المواد من إنتجرا للتجارة الإلكترونية</li>
                    <li>إعادة توزيع المحتوى من إنتجرا للتجارة الإلكترونية</li>
                </ul>

                <p>تبدأ هذه الاتفاقية في تاريخها. تم إنشاء أحكام وشروطنا بمساعدة <a href="https://www.termsandconditionsgenerator.com/">مولد الشروط و الأحكام المجاني</a>.</p>

                <p>تقدم أجزاء من هذا الموقع فرصة للمستخدمين لنشر وتبادل الآراء والمعلومات في بعض مناطق الموقع. إن شركة إنتجرا لحلول البناء وهندسة التقنيات لا تقوم بتصفية أو تحرير أو نشر أو مراجعة التعليقات قبل وجودها على الموقع. لا تعكس التعليقات آراء وآراء شركة إنتجرا لحلول البناء وهندسة التقنيات، ووكلاؤها و/أو الشركاء التابعين لها. تعكس التعليقات آراء وآراء الشخص الذي ينشر آراءه وآراءه. على النحو المسموح به بموجب القوانين ذات الصلة، لن تكون شركة إنتجرا لحلول البناء وهندسة التقنيات مسؤولة عن التعليقات أو عن أي مسؤولية أو أضرار أو نفقات ناتجة عن أي استخدام للتعليقات أو نشرها أو ظهورها على هذا الموقع.</p>

                <p>تحتفظ شركة إنتجرا لحلول البناء وهندسة التقنيات بالحق في مراقبة جميع التعليقات وإزالة أي تعليقات يمكن اعتبارها غير مناسبة أو مسيئة أو تسبب في انتهاك هذه الشروط و الأحكام.</p>

                <p>أنت تضمن وتمثل أن:</p>
                <ul>
                    <li>لديك الحق في نشر التعليقات على موقعنا ولديك جميع التراخيص والموافقات اللازمة للقيام بذلك؛</li>
                    <li>التعليقات لا تعتدي على حقوق الملكية الفكرية، بما في ذلك على سبيل المثال لا الحصر حقوق النشر، براءة الاختراع أو العلامة التجارية لأي طرف ثالث؛</li>
                    <li>التعليقات لا تحتوي على أي مواد مسيئة، مشهورة، مسيئة، فاحشة أو غير مشروعة تنتهك الخصوصية.</li>
                    <li>التعليقات لن تُستخدم لاستدراج أو ترويج الأعمال التجارية أو التخصيص أو عرض الأنشطة التجارية أو الأنشطة غير القانونية.</li>
                </ul>

                <p>بهذا تمنح إنتجرا لحلول البناء وهندسة التقنيات ترخيصًا غير حصريًا لاستخدام، استنساخ، تحرير وتفويض الآخرين لاستخدام، استنساخ وتحرير أي تعليقات خاصة بك بجميع الأشكال والأنماط أو وسائط الإعلام.</p>

                <h3><strong>ربط الصفحات</strong></h3>

                <p>الجهات التالية قد تقوم بربط موقعنا على الويب بدون الحصول على موافقة مكتوبة مسبقًا:</p>

                <ul>
                    <li>الجهات الحكومية؛</li>
                    <li>محركات البحث؛</li>
                    <li>وكالات الأنباء؛</li>
                    <li>موزعي الدلائل على الإنترنت قد يقومون بربط موقعنا على الويب بنفس الطريقة التي يقومون بها بربط مواقع الأعمال المدرجة الأخرى؛ و</li>
                    <li>الشركات المعتمدة على نطاق النظام باستثناء المنظمات الغير ربحية التي تقوم بجمع التبرعات الخيرية ومراكز التسوق الخيرية ومجموعات جمع التبرعات التي قد لا تربط بموقعنا على الويب.</li>
                </ul>
                <p>قد تقوم هذه المؤسسات بوضع روابط إلى صفحتنا الرئيسية أو إلى منشورات أخرى أو إلى معلومات موقع الويب الأخرى طالما أن الرابط: (أ) ليس بأي شكل من الأشكال مضللاً؛ (ب) لا يقول بشكل زائف أنه يتم ترعية، أو تأييد، أو موافقة من قبل الطرف الذي يقوم بالربط ومنتجاته و/أو خدماته؛ و (ج) يتناسب مع سياق موقع الطرف الذي يقوم بالربط.</p>

                <p>قد ننظر في ونوافق على طلبات الروابط الأخرى من أنواع المؤسسات التالية:</p>

                <ul>
                    <li>مصادر المعلومات الاستهلاكية و/أو التجارية المعروفة بشكل شائع؛</li>
                    <li>مواقع مجتمعية على الإنترنت؛</li>
                    <li>جمعيات أو مجموعات أخرى تمثل الجمعيات الخيرية؛</li>
                    <li>موزعي دليل الإنترنت عبر الإنترنت؛</li>
                    <li>بوابات الإنترنت؛</li>
                    <li>شركات المحاسبة والقانون والاستشارات؛ و</li>
                    <li>المؤسسات التعليمية وجمعيات التجارة.</li>
                </ul>

                <p>سنوافق على طلبات الروابط من هذه المؤسسات إذا قررنا أن: (أ) الرابط لن يجعلنا نبدو بشكل سلبي بالنسبة لأنفسنا أو لأعمالنا المعترف بها؛ (ب) المؤسسة ليس لديها أي سجلات سلبية لدينا؛ (ج) الفائدة لنا من رؤية الارتباط تعوض غياب إنتجرا لحلول البناء وهندسة التقنيات؛ و (د) الرابط يكون في سياق المعلومات العامة.</p>

                <p>قد تقوم هذه المؤسسات بوضع روابط إلى صفحتنا الرئيسية طالما أن الرابط: (أ) ليس بأي شكل من الأشكال مضللاً؛ (ب) لا يقول بشكل زائف أنه يتم ترعية، أو تأييد، أو موافقة من قبل الطرف الذي يقوم بالربط ومنتجاته أو خدماته؛ و (ج) يتناسب مع سياق موقع الطرف الذي يقوم بالربط.</p>

                <p>إذا كنت واحدًا من المؤسسات المدرجة في الفقرة 2 أعلاه وترغب في وضع رابط إلى موقعنا على الويب، يجب عليك إعلامنا عبر إرسال بريد إلكتروني إلى "إنتجرا لحلول البناء وهندسة التقنيات". يرجى تضمين اسمك، اسم منظمتك، معلومات الاتصال، بالإضافة إلى عنوان موقع الويب الخاص بك، وقائمة بعناوين URL من المواقع التي تنوي وضع روابط إليها على موقعنا على الويب، وقائمة بعناوين URL على موقعنا التي تود وضع روابط إليها. انتظر 2-3 أسابيع للحصول على رد.</p>

                <p>يمكن للمؤسسات المعتمدة والموافق عليها وضع روابط إلى موقعنا على الويب على النحو التالي:</p>

                <ul>
                    <li>من خلال استخدام اسم الشركة؛ أو</li>
                    <li>من خلال استخدام معرف الموقع على شبكة الإنترنت المرتبط؛ أو</li>
                    <li>من خلال استخدام أي وصف آخر لموقعنا على الويب المرتبط بالسياق وتنسيق محتوى موقع الطرف الذي يقوم بالربط.</li>
                </ul>

                <p>لن يُسمح باستخدام شعار أو أعمال فنية أخرى تابعة لـ "إنتجرا لحلول البناء وهندسة التقنيات" لوضع روابط دون اتفاق ترخيص لاستخدام العلامة التجارية.</p>

                <h3><strong>الإطارات المدمجة (iFrames)</strong></h3>

                <p>بدون موافقة مسبقة وإذن مكتوب، قد لا يتمكنك من إنشاء إطارات حول صفحات الويب الخاصة بنا التي تؤثر بأي شكل من الأشكال في العرض البصري أو المظهر لموقعنا على الويب.</p>

                <h3><strong>المسؤولية عن المحتوى</strong></h3>

                <p>لن نتحمل المسؤولية عن أي محتوى يظهر على موقع الويب الخاص بك. أنت توافق على حمايتنا والدفاع عنا ضد جميع الادعاءات التي تنشأ على موقع الويب الخاص بك. لا يجب أن تظهر أية روابط على أي موقع ويب يمكن تفسيرها على أنها تشهيرية أو مبتذلة أو غير قانونية، أو تنتهك حقوق الأطراف الثالثة بأي شكل من الأشكال أو تروج لانتهاك أو انتهاك آخر للحقوق.</p>

                <h3><strong>احتفاظ بالحقوق</strong></h3>

                <p>نحتفظ بالحق في طلب إزالة جميع الروابط أو أي رابط معين إلى موقعنا على الويب. توافق على إزالة جميع الروابط إلى موقعنا على الويب فور الطلب. نحتفظ أيضًا بالحق في تعديل هذه الشروط والأحكام وسياستنا للروابط في أي وقت. من خلال الربط المستمر بموقعنا على الويب، أنت توافق على الالتزام بهذه الشروط والأحكام للروابط.</p>

                <h3><strong>إزالة الروابط من موقعنا على الويب</strong></h3>

                <p>إذا وجدت أي رابط على موقعنا على الويب يعتبر مسيئًا بأي سبب، يمكنك الاتصال وإعلامنا في أي وقت. سننظر في طلبات إزالة الروابط ولكن ليس لدينا واجب أو التزام بالرد مباشرة عليك.</p>

                <p>نحن لا نضمن صحة المعلومات المتاحة على هذا الموقع، ونحن لا نضمن اكتمالها أو دقتها؛ ونحن لا نعد بضمان بقاء الموقع على الويب أو بقاء المواد على الموقع محدثة.</p>

                <h3><strong>إخلاء المسؤولية</strong></h3>

                <p>إلى أقصى حد يسمح به القانون المعمول به، نستبعد جميع التصورات والضمانات والشروط المتعلقة بموقع الويب واستخدام هذا الموقع. لن يحدث شيء في هذا الإخلاء الضمانات:</p>

                <ul>
                    <li>يحد أو يستبعد مسؤوليتنا أو مسؤوليتكم عن الوفاة أو الإصابة الشخصية؛</li>
                    <li>يحد أو يستبعد مسؤوليتنا أو مسؤوليتكم عن الاحتيال أو التمثيل الاحتيالي؛</li>
                    <li>يحد أي من مسؤوليتنا أو مسؤوليتكم بأي طريقة لا تُسمح بها بموجب القانون المعمول به؛ أو</li>
                    <li>يستبعد أي من مسؤوليتنا أو مسؤوليتكم التي قد لا يُمكن استبعادها بموجب القانون المعمول به.</li>
                </ul>

                <p>القيود والمنع المفروضة على المسؤولية في هذا القسم وفي أي مكان آخر في هذا الإخلاء الضمانات: (أ) تخضع للفقرة السابقة؛ و (ب) تحكم في جميع المسؤوليات الناشئة بموجب إخلاء الضمانات، بما في ذلك المسؤوليات الناشئة من العقد، أو من الظلم، أو عند خرق الواجب القانوني.</p>
            </> : <>
                <h2><strong>Terms and Conditions</strong></h2>

                <p>Welcome to Integra E-commerce!</p>

                <p>These terms and conditions outline the rules and regulations for the use of Integra for building and IT solutions's Website, located at https://www.integraegy.com.</p>

                <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Integra E-commerce if you do not agree to take all of the terms and conditions stated on this page.</p>

                <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of eg. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

                <h3><strong>Cookies</strong></h3>

                <p>We employ the use of cookies. By accessing Integra E-commerce, you agreed to use cookies in agreement with the Integra for building and IT solutions's Privacy Policy. </p>

                <p>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

                <h3><strong>License</strong></h3>

                <p>Unless otherwise stated, Integra for building and IT solutions and/or its licensors own the intellectual property rights for all material on Integra E-commerce. All intellectual property rights are reserved. You may access this from Integra E-commerce for your own personal use subjected to restrictions set in these terms and conditions.</p>

                <p>You must not:</p>
                <ul>
                    <li>Republish material from Integra E-commerce</li>
                    <li>Sell, rent or sub-license material from Integra E-commerce</li>
                    <li>Reproduce, duplicate or copy material from Integra E-commerce</li>
                    <li>Redistribute content from Integra E-commerce</li>
                </ul>

                <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the <a href="https://www.termsandconditionsgenerator.com/">Free Terms and Conditions Generator</a>.</p>

                <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Integra for building and IT solutions does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Integra for building and IT solutions,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Integra for building and IT solutions shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

                <p>Integra for building and IT solutions reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

                <p>You warrant and represent that:</p>

                <ul>
                    <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                    <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                    <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                    <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                </ul>

                <p>You hereby grant Integra for building and IT solutions a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

                <h3><strong>Hyperlinking to our Content</strong></h3>

                <p>The following organizations may link to our Website without prior written approval:</p>

                <ul>
                    <li>Government agencies;</li>
                    <li>Search engines;</li>
                    <li>News organizations;</li>
                    <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                    <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                </ul>

                <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>

                <p>We may consider and approve other link requests from the following types of organizations:</p>

                <ul>
                    <li>commonly-known consumer and/or business information sources;</li>
                    <li>dot.com community sites;</li>
                    <li>associations or other groups representing charities;</li>
                    <li>online directory distributors;</li>
                    <li>internet portals;</li>
                    <li>accounting, law and consulting firms; and</li>
                    <li>educational institutions and trade associations.</li>
                </ul>

                <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Integra for building and IT solutions; and (d) the link is in the context of general resource information.</p>

                <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>

                <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Integra for building and IT solutions. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

                <p>Approved organizations may hyperlink to our Website as follows:</p>

                <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
                </ul>

                <p>No use of Integra for building and IT solutions's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

                <h3><strong>iFrames</strong></h3>

                <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

                <h3><strong>Content Liability</strong></h3>

                <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

                <h3><strong>Reservation of Rights</strong></h3>

                <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

                <h3><strong>Removal of links from our website</strong></h3>

                <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

                <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

                <h3><strong>Disclaimer</strong></h3>

                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

                <ul>
                    <li>limit or exclude our or your liability for death or personal injury;</li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ul>

                <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

                <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
            </>}
        </Container>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
