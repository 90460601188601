import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const currentLocale = Cookies.get("i18next") || "en";

export const getOrders = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.super.orders.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getOrder = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.super.orders.getOne)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const editOrderStatus = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    putRequest(data, {locale: currentLocale}, undefined, token, endpoints.super.orders.getOne)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const sendPriceList = (data, store) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, {locale: currentLocale}, undefined, token, endpoints.super.sendPriceList)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: WAIT_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

// Analytics
export const getProductsSales = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({...data, locale: currentLocale}, undefined, token, endpoints.super.analytics.sales.products)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getProductSales = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({...data, locale: currentLocale}, undefined, token, endpoints.super.analytics.sales.product)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getCategoriesSales = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({...data, locale: currentLocale}, undefined, token, endpoints.super.analytics.sales.categories)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getCategorySales = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({...data, locale: currentLocale}, undefined, token, endpoints.super.analytics.sales.category)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getMethodsSales = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest({...data, locale: currentLocale}, undefined, token, endpoints.super.analytics.sales.methods)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const sendNotification = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, {locale: currentLocale}, undefined, token, endpoints.super.notifications)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};